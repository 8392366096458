.main_dynamic_p {
  // border-bottom: 4px solid transparent;
  // border-image: linear-gradient(90deg, #FFD913 0%, #4E4ADD 100%);
  // border-image-slice: 1;
  background: linear-gradient(270deg, #911DC1 0%, #D4307A 100%);
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(90deg, #2AC0FF 0%, #921EC1 100%);
  border-image-slice: 1;
}

.main_dynamic {
  background: linear-gradient(270deg, #911DC1 0%, #D4307A 100%);
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(90deg, #2AC0FF 0%, #921EC1 100%);
  border-image-slice: 1;

  .dynamic_app {
    color: #ffff;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_dynamic {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        color: #ffff;
        font-size: 14px !important;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .get_app {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      a {
        color: #1c64b1;

        p {
          border: none;
          color: #ffff;
          font-size: 10px;
          margin: 0px;
          font-weight: 300;
        }
      }
    }
  }
}

.logo_dynamic img {
  background: #161616;
  border: 0.5px solid #FFFFFF;
  border-radius: 5px;
  padding: 5px;
}

.back_to_school {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.12);
  z-index: 10;
  overflow: hidden;

  .image_back_to_school {
    position: absolute;
    object-fit: cover;
    z-index: -1;
    width: 450px;
  }

  h5 {
    margin: 0;
    font-weight: 500;
  }

  h4 {
    margin: 0 20px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
  }


  h1 {
    margin: 0;
    color: black;
    font-weight: 700;
    font-size: 2.2rem;
    text-transform: uppercase;

    span {
      color: #2697EF;
    }
  }

  h2 {
    font-weight: 700;
    color: #2697EF;
    text-transform: uppercase;
    margin: 0 10px;

    span {
      color: black;
    }

    h5 {
      text-transform: capitalize;
      color: #000000;
    }
  }

  button {
    border-radius: 30px;
    background: #2697EF;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
  }
}

@media (max-width:991px) {
  .back_to_school {
    gap: 0px;
    padding: 10px;
    justify-content: space-between;

    h1 {
      font-size: 1.7rem;
    }

    button {
      padding: 0 10px;
      margin: 0 !important;
    }

    h2 {
      font-size: 1.5rem;

      h5 {
        font-size: 0.9rem;
      }
    }
  }
}