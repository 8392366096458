.select {
  border: 1px solid #e1e1e1;
  position: absolute;
  border-radius: 20px;
  padding: 10px;
  width: 94%;
  background-color: #fff;
}

.wrapper_select {
  position: relative;
  margin-top: 32px;
  cursor: pointer;
  margin: auto;
}

.select_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.select_body {
  border-radius: 10px;
  width: 100%;
}

.select_body_item1 {
  padding: 15px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
}

.select_body_item {
  padding: 15px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #e1e1e1;
}

.select_body_items {
  padding: 15px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
