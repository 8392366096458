.container {
    background-image: url("../../assets/img/loading-images/popup-bg.png");
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}


.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    z-index: 20;
}

.right {
    text-align: center;

    img {
        width: 125px;
    }

    h1 {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        margin: 0;

        span {
            font-size: 40px;
            margin: 0 10px;
        }
    }

    h4 {
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        margin:0 auto 10px;
        font-weight: 700;
        color: white;
        font-size: 23px;
        background-color: #632f95;
        border-radius: 10px;
        padding: 10px;
        width: 160px;
    }

    p {
        font-size: 1.3rem;
        color: #FDA5FF;
        margin-top: 10px;
    }

    button {
        background: linear-gradient(90.47deg, #006CC8 29.92%, #35BBE9 86.66%);
        border-radius: 40px;
        border: none;
        color: white;
        margin: 20px 0;
        padding: 6px 25px;
        font-size: medium;
    }
}

.time {
    display: flex;
    justify-content: center;
    gap: 10px;

    div {
        width: 80px;
        background-color: #080F1A;
        border-radius: 10px;
        padding: 10px;

        h1 {
            font-weight: bolder;
        }

        h6 {
            color: #A5DEFF;
            font-weight: 200;
        }
    }
}

@media (min-width:900px) {
    .right {
        img {
            width: 200px;
        }
    }
}

@media (min-width:1200px) {
    .container {
        flex-direction: row;
    }

    .right {
        margin: auto;

        h1 {
            font-size: 40px;
        }

        h2 {
            margin-bottom: 20px;
        }
    }
}