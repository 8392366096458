.rc-switch {
  background: #1dc7ea !important;
  box-shadow: none !important;
}

.rc-switch-checked {
  background: #d751ef !important;
  border: white;
  box-shadow: none !important;
}


.plans_component {
  display: flex;
  row-gap: 40px;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  width: 100%;
}

.skeleton_plans {
  background-color: #fafdff;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 0 7.5px;
}

.skeleton_plans p {
  font-size: 18px;
  text-align: center;
  margin: 0px;
}

.wpwl-form-card {
  background-image: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}

.wpwl-button-pay {
  position: relative !important;
  left: unset !important;
  transform: unset !important;
  bottom: unset !important;
  margin: auto !important;
}

.wpwl-form {
  margin-bottom: unset !important;
}