.business_tools {
  background-color: #fafdff;
  width: 100%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  height: 100%;
  padding-bottom: 50px;
  position: relative;
  // .container {
  //   max-width: 1340px;
  // }

  .business_title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    text-align: center;
    h4 {
      font-size: 40px;
      font-weight: 500;
      line-height: 58px;
      // font-family: "Kanit", sans-serif !important;
      color: #000000;
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 22.4px;
    }
  }
}
.business_filter_list::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.Nav_Business_tools {
  display: flex;
  align-items: center;
  justify-content: center;
}
.business_filter_list a:hover {
  background-color: #1877F2;
}
.business_filter_list a.active {
  background-color: #1877F2;
}
.business_filter_list a.active span {
  color: #fff !important;
      background-color: transparent;
}
.business_filter_list a:hover span {
  color: #fff !important;
      background-color: transparent;
}
.business_filter_list {
  flex-wrap: nowrap;
  overflow-x: scroll;
  // padding: 0px 5px 5px 5px;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  a {
    border: 1px solid #e4e4e4;
    border-radius: 20.05px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    // width: 100%;

  }
  span {
    color: #a5a5a5;
    font-size: 15px;
    line-height: 22.43px;
    font-weight: 500;
    // font-family: "Kanit", sans-serif !important;
  }
}

.business_filter_list_ar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  :hover {
    background-color: #1877f2;
    span {
      color: #fff !important;
    }
  }
  a {
    border: 1px solid #e4e4e4;
    border-radius: 20.05px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    color: #a5a5a5;
    font-size: 15px;
    line-height: 22.43px;
    font-weight: 500;
    font-family: "Kanit", sans-serif !important;
  }
}

.templete_business {

  .template_child{
    display: flex;
    justify-content: space-between;
    align-items: center ;
    .badge_mains{
      background-color: #f8c133;
      color:#fff;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .busines_mains {
    display: flex;
    // justify-content: space-between;
    grid-template: 30px !important;
    row-gap: 20px;
    flex-wrap: wrap !important;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 29.9px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  p {
    margin-bottom: 1px;
    font-size: 13px;
    // line-height: 26.8px;
    color: #b4b4b4;
  }

  .tem_busi {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    stroke: #d7d7d7;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e4e4;
    padding: 7px;
    border-radius: 10px;
    min-height: 131px !important;
    // width: 31%;
    position: relative;
    // margin-top: 50px;
    .icons_badge {
      position: absolute;
      top: 0px;
      right: 19px;
      transform: translateY(13px);
      svg {
        transition: 0.5s;
        cursor: pointer;
        :hover {
          fill: #f8c133 !important;
        }
      }
    }
    .icons_badge_ar{
      top: 0px;
      left: 20px;
      position: absolute;
      transform: translateY(8px);
      svg {
        transition: 0.5s;
        cursor: pointer;
        :hover {
          fill: #f8c133 !important;
        }
      }
    }
    .busines_icon {
      display: flex;

      justify-content: center;
      align-items: center;
      border-radius: 50%;
      // padding: 13px;
    }
    .busines_icon img {
      width: 47px;
      height: 47px;
    }
  }
  .minHeightDiff {
    min-height: 141px !important;
  }
  .template_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .business_deatils {
    h6 {
      margin: 0px;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      text-transform: unset
    }
  }
}

@media screen and (max-width: 768px) {
  // .busines_mains {
  //   display: block !important;
  // }
  .business_title {
    text-align: center !important;
    h4 {
      font-size: 26px !important;
      line-height: 20px;
    }
  }
  .badge_business {
    right: 21px !important;
    transform: translateY(90px) !important;
    top: 158px !important;
  }

}
@media screen and (min-width: 610px) and (max-width: 1200px) {
  .busines_mains {
    display: flex !important;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .business_filter_list {
    gap: 9px !important;
  }
  
}

@media screen and (min-width : 770px) and (max-width: 1400px) {
  .business_filter_list {
    gap: 15px !important;
  }

  .business_filter_list_ar{
    left: 21px !important;
    top: 158px !important;
  }
}



::-webkit-scrollbar {
  display: none;
}
.templete_business a:hover {
  box-shadow: none;
    outline: 2px solid #4472f16c;
    /* border-bottom: 6px solid #4472f16c; */
    transition: border 0.2s ease-out;
}
