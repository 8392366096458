.error-modal .modal-content {
    border-radius: 4.5rem;
    padding: 0px !important;
    min-width: 450px;
}

.error-modal .modal.show .modal-dialog {
    transform: none !important;
}

.error-modal .modal-content .modal-header {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    background-clip: none !important;

}

.error-modal .modal-content .modal-body {
    background-color: white;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 4.5rem;
    border-bottom-right-radius: 4.5rem;
}

.error-modal .btn.common-btn-modal {
    padding: 15px 60px;
    transform: scale(0.9);
    border-radius: 15px;
    background: rgb(17, 113, 216);
    background: linear-gradient(45deg, rgba(60, 200, 235, 1) 0%, rgba(17, 113, 216, 1) 100%);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 800;
    border: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 30px 0px;
}

.error-modal .blue-gradient-circle {
    height: 12px;
    width: 12px;
    background: linear-gradient(rgba(60, 200, 235, 1), rgba(17, 113, 216, 1));
    border-radius: 50px;
}

@media (max-width: 450px) {
    .error-modal .modal-content {
        min-width: 100%;
    }
}