.payment-method-container {
    padding: 3rem;
}
.payment-method-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.payment-method-heading {
    color: #9a9a9a;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.5px;
    line-height: 30px;
}

.payment-method-title {
    color: #050505;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.6px;
    line-height: 30px;
}

.payment-method-card {
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 37px;
    box-shadow: 0px 0px 20px #00000014;
    padding: 38px 51px;
}

.payment-method-card-group-1 {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 28px 25px;
}

.payment-method-card-group-1-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-method-card-group-1-main {
    display: flex;
    align-items: center;
    gap: 33px;
}

.payment-method-card-img {
    padding: 15px 8px;
    border-radius: 8px;
    height: 66px;
    width: 99px;
    background-color: rgba(255, 255, 255, 1);
}

.payment-method-card-dot {
    width: 7px;
    height: 6px;
    border-radius: 3.43px/3px;
    background-color: rgba(13, 13, 13, 1);
}

.payment-method-card-number {
    letter-spacing: 0px;
    line-height: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(13, 13, 13, 1);
}

.payment-method-card-expiry-date {
    letter-spacing: 0px;
    line-height: 25px;
    color: "rgba(13, 13, 13, 1)";
    font-weight: 400;
    font-size: 20px;
}

.payment-method-card-primary-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    border-radius: 8px;
    border: 1px solid rgba(112, 112, 112, 1);
    padding: 5px;
}

.payment-method-card-primary {
    line-height: 1px;
    letter-spacing: 0px;
    color: rgba(13, 13, 13, 1);
    font-size: 20px;
    font-weight: 400;
}

.payment-method-card-edit {
    padding: 15px 8px;
    color: rgba(13, 13, 13, 1);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 20px;
}

.payment-method-card-p {
    letter-spacing: -0.8px;
    line-height: 20px;
    color: rgba(5, 5, 5, 1);
    font-size: 20px;
    font-weight: 500;
}

.payment-method-card-group-2 {
    display: flex;
    gap: 10%;
}

.payment-method-card-group-2-wrapper {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
}

.payment-method-card-group-2-p {
    letter-spacing: -0.56px;
    line-height: 30px;
    color: rgba(116, 116, 116, 1);
    font-size: 14px;
    font-weight: 400;
}

@media (max-width: 750px) {
    .payment-method-card-group-2 {
        display: flex;
        gap: 15px;
        flex-direction: column;
    }
    .payment-method-card-group-2-wrapper {
        gap: 15px;
        align-items: unset;
    }
    .payment-method-container {
        padding: 1rem;
    }
}

@media (max-width: 669px) {
    .payment-method-card {
        padding: 38px 25px;
    }
}

@media (max-width: 500px) {
    .responsive-wrapper {
        display: none !important;
    }
}

@media (max-width: 600px) {
    .payment-method-card-group-1-wrapper {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
    }
    .payment-method-card-group-1-main {
        align-items: unset;
        flex-direction: column;
    }
    .payment-method-card-edit {
        padding: 33px 8px;
    }
}

@media (max-width: 300px) {
    .payment-method-card {
        padding: 38px 15px;
    }
}
