.upgrade_modal_doc {
    border-radius: 15px;

    font-family: Montserrat;
}

.upgrade_modal_doc_content {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        flex: 120%;
    }

    h1 {
        color: black;
        font-weight: 700;
        margin: 0;
        font-size: 2rem;
    }

    h6 {
        text-transform: capitalize;
        font-weight: 500;
        color: black;
        font-size: 1rem;
        margin: 15px 0;
        line-height: 1.7rem;

    }

    ul {
        font-weight: 600;
        font-size: 1.1rem;
        list-style-image: url('./Check.svg');
        list-style-position: outside;
        list-style-type: square;

        li {
            font-weight: 400;
            line-height: 1.5rem;
            font-size: 1rem;
        }

    }

}

.upgrade_modal_doc_image {
    position: relative;
    top: 70px;
}


.upgrade_doc_footer {
    padding-left: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 20px;

    span {
        color:
            #A3A2B2;
        font-weight: 600;
    }

    button {
        border-radius: 8px;
        background: linear-gradient(27deg, #00D1FF 0%, #0047FF 100%);
        color: white;
        border: none;
        padding: 5px 20px;
    }
}

@media (max-width: 991px) {
    .upgrade_modal_doc {
        background: unset
    }

}