/** Used to define container behavior: width, position: fixed etc... **/
/* .english_direction .Toastify__toast-container {
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0px);
    z-index: 9999;
}

.arabic_direction .image_gen_header .Toastify__toast-container{
    right: 50% !important;
    position: fixed !important;
    transform: translate(0px, -50%) !important;
    z-index: 9999;
} */

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
 
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {

}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
    
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast--default {
    background: #ffffff
}
.Toastify__toast--info {
    background: #ffffff
}
.Toastify__toast--success {
    background: #ffffff
}
.Toastify__toast--warning {
    
    background: #ffffff
}
.Toastify__toast--error {
    background: #ffffff
}

.Toastify__progress-bar {
    visibility: hidden;
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
}