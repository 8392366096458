.css-hh5r23-MuiSvgIcon-root:hover {
    border: 2px solid #4472f16c !important;
 }

 .css-1ygcj2i-MuiTableCell-root {
    font-size: 18px !important;
}
 
 @media (max-width: 700px) {
    .table-body-label {
        font-size: 12px;
    }
    .css-1ygcj2i-MuiTableCell-root {
        font-size: 15px !important;
    }
  }