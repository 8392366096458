.wrapper {
  border: var(--border);
  color: var(--text-color);
  background: var(--background-color);

  border-radius: var(--border-radius);
  // transition: all 0.3s;
  transition: all 0.3s ease-in-out;

  padding: var(--padding);
  // font-family: "Poppins";
  padding: var(--padding);
  background-size: 200% 100%;
  box-shadow: var(--shadow);

  p {
    font-size: 18px;
    text-align: center;
    font-weight: var(--font-weight);
    padding: 0;
    margin: 0;
  }

  &:hover {
    border: var(--hover-border);
    border-radius: var(--border-radius);
    color: var(--hover-text-color);
    background: var(--hover-background-color);
    background-size: 200% 100%;
    box-shadow: var(--hover-shadow);
  }
}
