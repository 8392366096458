.headShot_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.headShot_buttons {
    border: 1px solid #E3E3E3;
    border-radius: 25px;
    padding: 0.1rem 1.5rem;
    background-color: white;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    gap: 10px;

    &:hover {
        outline: 2px solid rgba(0, 68, 255, 0.7);
    }
}