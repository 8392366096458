.wavy {
    position: relative;
    -webkit-box-reflect: below -12px linear-gradient(
        transparent, rgba(0,0,0,0.2)
    );
}
.wavy span {
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    animation: animate 1.5s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
}
@keyframes animate {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-20px);
    }
    40%,100% {
        transform: translateY(0px);
    }
}
@media (max-width: 600px) {
    .wavy span {
        font-size: 11px;
    }
}
@media (max-width: 300px) {
    .wavy span {
        font-size: 8px;
    }
}