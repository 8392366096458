.filter-header-align {
  width: 100%;
  justify-content: flex-start;
  margin-left: 25%;
  align-items: center;
  padding: 10px;
  gap: 30px;
}
.filter-header-align-right {
  width: 100%;
  justify-content: flex-start;
  margin-right: 5%;
  align-items: center;
  padding: 10px;
  gap: 30px;
}
@media (max-width: 1200px) {
  .filter-header-align {
    justify-content: center !important;
    margin-left: 0;
  }
  .filter-header-align-right {
    justify-content: center !important;
    margin-right: 0;
  }
}

.filter_button {
  padding: 0px 15px;
  height: 45px;
  width: 100%;
  white-space: nowrap;
}

.filter_wrapper a {
  width: 100%;
  font-size: 16px;
  border-radius: 50px;
  transition: all 0.1s linear;
}

.filter_wrapper a.active {
  height: 45px;
  white-space: nowrap;
  padding: 0px 15px;
  width: 100%;
  transition: all 0.1s linear;
}

.swiper-slide {
  /* max-width: 100% !important; */
  /* width: 25% !important; */
  display: flex;
}

/* .swiper-container,
.swiper-wrapper {
    display: flex;
    width: 100%;
    position: relative;
} */

.plan-type .filter_wrapper button {
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
}

.plan-type .filter_wrapper a svg {
  width: 21px;
  height: 17px;
}

.plan-type .filter_wrapper button {
  color: #1877f2;
  border: 0px solid #1877f2;
  border-radius: 20px;
  transition: 0.5s;
}

.plan-type button.arabicFont,
button.englishFont {
  border: 1px solid #fff;
  padding: 4px 8px 9px;
  border-radius: 11px;
}

.plan-type .filter_wrapper button:hover {
  color: #1877f2;
  border: 1px solid #1877f2;
}

.plan-type .filter_wrapper button.active {
  background-color: #1877f2;
  color: #fff;
  border: unset;
}

.plan-type .rc-switch-checked {
  /* border: 1px solid transparent;
  background-color: #d851ef !important; */
  background: #ffffff;
  border: 0.5px solid #d7d7d7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
.rc-switch:after {
  background-color: hsl(0, 0%, 100%) !important;
  width: 16px;
  height: 16px;
  top: 2px;
}

.plan-type .rc-switch {
  /* border: 1px solid transparent;
  background-color: #1dc7ea;
  box-shadow: none; */
  background: #ffffff;
  border: 0.5px solid #d7d7d7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
.filter-label {
  margin-bottom: 0px;
  margin-left: 13px;
  margin-right: 13px;
}
.filter-label-ar {
  margin-bottom: 0px;
  margin-right: 0px;
  font-size: 19px;
}
.input_wrapper-ar {
  margin-right: 40px;
}

@media (max-width: 1200px) {
  .filter_wrapper a {
    display: flex;
    width: 100%;
    font-size: 16px;
    padding: 20px;
    min-width: unset;
    border-radius: 50px;
    transition: all 0.1s linear;
  }

  .filter_wrapper a.active {
    height: 45px;
    white-space: nowrap;
    /* padding: 0px 110px; */
    display: flex;

    width: 100%;
    transition: all 0.1s linear;
  }

  .filter-label-ar {
    margin-bottom: 10px;
  }
  .input_wrapper-ar {
    margin-right: 0px;
  }
}

.new-search-bar img:nth-of-type(1) {
  object-fit: cover;
  height: 25px;
  width: 25px;
}
@media (max-width: 425px) {
  .filter_wrapper a.active {
    height: 35px;
  }
}

.new-search-bar {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.new-search-bar img:nth-of-type(2) {
  object-fit: cover;
  height: 15px;
  width: 15px;
}

.new-search-bar input {
  border: none;
  background-color: transparent !important;
}
.new-search-bar input:focus {
  background-color: transparent !important;
}

@media (min-width: 428px) {
  .new-search-bar {
    padding: 8px 10px;
  }
}
