/* modal css start here*/
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .popup-inner {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0px 0px 6px 3px #0f6ad417;
    width: 484px;
   
  }
  
  
  .subscribe-btn{
    margin-left: auto;
    padding: 7px 27px;
    transform: scale(0.9);
    border-radius: 18px;
    background: linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%);
    color: #fff !important;
    text-decoration: none !important;
    border: none;
    margin: 0 auto;
    display: table;
    margin-top: 17px;
    font-size:'18px';
    font-weight:'700';
    margin-bottom: 10px;
}
.subscribe-btn:hover {
    transform: scale(1);
    border-radius: 30px;
    background-color: var(--text-color);
    opacity: 1;
    color: #fff;
    transition: all 0.5s ease;
}
  .popup h4 {
    margin-top: 0;
    text-align: center;
  }
  
  .popup p {
    margin-bottom: 0;
  }
  
  .popup button {
    margin-top: 10px;
  }