.container {
    padding: 20px 0;
    position: relative;

    h2 {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        margin: 0;
        color: black;
    }

    .head {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        left: 32%;
        gap: 10px;

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;

        }
    }

    h4 {
        color: #0E92FC;
        font-weight: 600;
        font-size: 16px;
        margin: 0;
        font-family: "Montserrat";
        text-align: center;
    }
}

@media (max-width:576px) {
    .content {
        min-height: 350px !important;
        // .valid{
        //     width: 80% !important;
        // }
    }
}

.content {
    background-color: #F4F5F9;
    padding: 0 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: 400px;
    position: relative;

    .exist {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

    }

    h4 {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        color: black;
    }

    .input {
        background-color: white;
        display: flex;
        align-items: center;
        padding: 0 10px;
        gap: 10px;
        border-radius: 6px;

        img {
            &:nth-child(3) {
                background-color: #0C8FFC;
                border-radius: 5px;
                cursor: pointer;
                object-fit: cover;
            }
        }
    }

    .valid {
        position: absolute;
        right: 0;
        padding: 20px 40px;

        h6 {
            float: right;
            font-weight: 600;
            color: black;
            font-size: 1.5rem;
        }

        button {
            position: relative;
            bottom: 0;
            left: 55%;
            background: none !important;
            color: #0E92FC;
            padding: 0;
        }
    }

    input {
        width: 100%;
        border: none;
        padding: 10px;
        margin: 0;

        &:focus {
            border: none !important;
        }
    }

    .Input {
        padding: 20px 0;
        border-radius: 6px;
    }

    button {
        // position: absolute;
        // bottom: 20px;
        // left: 50%;
        // transform: translate(-50%, -50%);
        margin: 10px auto;
        text-align: center;
        padding: 8px 10px;
        color: white;
        background: linear-gradient(95.71deg, #0C8FFC 31.09%, #3ABCFC 100.76%);
        border-radius: 6px;
        border: none;
        width: 250px;
    }

    .skeleton_button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 20px 24px;
        border-radius: 6px;
        border: none;
        display: block;
        width: 60%;
    }
}

.h1 {
    display: none;
    position: absolute;
    color: #F3F3F3;
    font-size: 130px;
    font-weight: bold;
    right: 0;
    bottom: -50px;
    z-index: 2;
}

.banner {
    position: relative;
    bottom: 10px;
    overflow: hidden;
    width: 90%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 34px -5px rgba(219, 219, 219, 0.75);
    justify-content: space-between;
    border-radius: 12px;
    height: 80px;
    z-index: 20;
    background-position: center center;
    background-size: cover;
    background-image: url("../../../assets/img/loading-images/mini-popup.png");
    height: 100px;



    .contents {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;


        img {
            width: 120px;
            height: 100px !important;
            object-fit: cover;
            display: block;
            position: relative;
            top: 15px;
            z-index: 20;
            height: 100%;
            object-fit: cover;
        }

        h2 {
            margin: 0;
            color: white;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: unset;
        }

        h1 {
            color: white !important;
            margin: 0 5px;
            font-weight: 800;
            height: unset !important;
            -webkit-text-fill-color: white;
            font-size: 2.5rem;

            span {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }

        button {
            max-width: 150px;
            background: linear-gradient(90.47deg, #006CC8 29.92%, #35BBE9 86.66%);
            border-radius: 40px;
            color: white;
            padding: 5px 15px;
            font-size: 1rem;
            font-weight: bold;
            z-index: 5;
            position: relative;
            border: none;
        }

        p {
            margin: 0;
            color: white;
            font-size: 0.7rem;
        }

        .column {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

}

.payment_head {
    padding: 0 20px 20px;

    h5 {
        margin: 0;
    }

    h4 {
        margin: 5px 0;
    }

    h6 {
        margin-top: 10px;

    }

    h3 {
        margin: 0;
        color: rgb(26, 130, 220);
    }
}


.required::after {
    content: "*";
    color: red;
    margin-left: 5px;
}

@media (max-width:600px) {
    .banner {


        .contents {
            img {
                display: none;
            }

            .column {
                flex-direction: column;
                gap: 0;
            }
        }
    }

}

@media (max-width:1000px) {
    .banner {
        height: 80px;

        .contents {
            img {
                width: 100px;
                height: 100px;
            }

            h2 {
                font-size: 1.3rem
            }

            h1 {
                height: unset;
                font-size: 2.5rem;
            }

            button {
                min-width: 82px;
            }
        }

    }
}

@media (min-width:991px) and (max-width:1300px) {
    .banner {
        h2 {
            margin: 30px 0 0 0;
            padding-top: 10px;
        }

        img {
            width: 250px;
        }

        button {
            width: 150px;
        }
    }
}

@media (min-width:1600px) {
    .banner {
        position: relative;
        // bottom: 10px;
        // left: 50%;
        // transform: translate(-50%);
        margin: auto;
        width: calc(100vw - 30px);
        max-width: 850px;
        align-items: center;
    }
}

.paypal-button.paypal-button-shape-rect{
    border-radius: 5px !important;
}