.marketing {
  background-color: #fff;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 40px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  flex-grow: 1;
  // .container {
  //   max-width: 1450px;
  // }
  .marketing_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
      width: 85%;
      background: #ffffff;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      height: 46px;
    }
    label {
      padding-top: 60px;
      color: #000000;
      font-size: 15px;
      font-weight: 500;
    }
      .btn_maketing {
        background: linear-gradient(
          45deg,
          rgba(17, 113, 216, 1) 0%,
          rgba(60, 200, 235, 1) 100%
        );
        border: none;
        border-radius: 8px;
        height: 50px;
        margin-top: 30px;
        color: #ffffff;
     
    }
  }
}
.export_pdf{
   background-color: #ffffff;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-end;
   padding: 20px;
   gap: 40px;
   button{
    margin-top: 20px;
    width: 115px;
    border: none;
    padding: 8px 20px;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 13px;
    border: 0.5px solid #D7D7D7;
    border-radius: 10px;
}
}
.export_pdf_ar {
  background-color: #ffffff;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-end;
   padding: 20px;
   gap: 40px;
   button{
    margin-top: 20px;
    width: 115px;
    border: none;
    padding: 8px 20px;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 13px;
    border: 0.5px solid #D7D7D7;
    border-radius: 10px;
}
}

@media screen and (max-width: 700px) {
  .marketing {
    padding: 20px;
    .marketing_main {
      gap: 10px;
      input {
        width: 100%;
        height: 40px;
      }
      label {
        padding-top: 15px;
        font-size: 13px;
      }
    }
      .btn_maketing {
        height: 40px !important;
        font-size: 12px !important;
        margin-top: 10px;
      
    }
  }
}

@media screen and (min-width: 710px) and (max-width: 1400px) {
  .marketing {
    padding: 20px;
    .marketing_main {
      input {
        width: 100%;
        height: 42px;
      }
      label {
        padding-top: 35px;
        font-size: 14px;
      }
    }

      .btn_maketing {
        height: 45px !important;
        margin-top: 30px;
    }
  }
}
// @media screen and (min-width: 1450px) and (max-width: 2200px) {
//     .btn_maketing{
//         position: relative;
//         transform: translateX(-100px);
//     }

// }
.export_pdf ol, .export_pdf_ar ol {
  padding: 10px;
}
.export_pdf ul, .export_pdf_ar ul {
  padding: 10px;
}
.export_pdf li, .export_pdf_ar li {
  margin-bottom: 10px;
}
.export_pdf table, .export_pdf_ar table {
  margin: 0px 0px 40px 0px;
  // outline: 1px solid #D7D7D7;
  border-radius: 12px;
  width: 100%;
  min-width: 750px;
  overflow: hidden !important;
  min-height: unset !important;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);

}
.export_pdf thead, .export_pdf_ar thead {
  border-bottom: 1px solid #D7D7D7;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.067);
  // background: linear-gradient(90deg, #151723, #23273a);
}
.export_pdf thead tr th, .export_pdf_ar thead tr th {
  padding: 10px 5px 10px 5px;
  font-weight: 600;
  font-size: 15px;
  font-weight: bold;
  // color: #fff;
}
.export_pdf thead tr th {
  font-family: 'SohneBold';
}
.export_pdf_ar thead tr th {
  font-family: 'Tajawal_black';
}
.export_pdf thead tr th {
  border-right: 1px solid #D7D7D7;
}
.export_pdf thead tr th:last-child {
  border-right: unset;
}
.export_pdf_ar thead tr th {
  border-left: 1px solid #D7D7D7;
}
.export_pdf_ar thead tr th:last-child {
  border-left: unset;
}
.export_pdf tbody tr, .export_pdf_ar tbody tr{
  border-bottom: 1px solid #D7D7D7;
}
.export_pdf tbody tr:last-child, .export_pdf_ar tbody tr:last-child {
  border-bottom: unset;
}
.export_pdf tbody tr td, .export_pdf_ar tbody tr td{
  padding: 5px;
}
.export_pdf tbody tr td {
  border-right: 1px solid #D7D7D7;
}
.export_pdf tbody tr td:last-child {
  border-right: unset;
}

.export_pdf_ar tbody tr td {
  border-left: 1px solid #D7D7D7;
}
.export_pdf_ar tbody tr td:last-child {
  border-left: unset;
}
.export_pdf p, .export_pdf_ar p {
  font-weight: 800;
}
.export_pdf_ar p {
  font-family: 'Tajawal';
}
.export_pdf table {
  margin: 0px 0px 40px 0px;
}
.export_pdf p {
  font-weight: 800;
}
.tippy_custom .tooltip_styling_ar, .tippy_custom .tippy-content, .tippy_custom, .tippy_custom .tooltip_styling {
  min-width: 410px !important;
  max-width: 450px !important;
}

@media screen and (max-width: 500px) {
  .tippy_custom .tooltip_styling_ar, .tippy_custom .tippy-content, .tippy_custom {
    min-width: calc(100vw - 50px) !important;
    max-width: calc(100vw - 10px) !important;
  }
}