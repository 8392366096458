.profile-steps-page {
    height: 100vh;
}

.pt-6 {
    padding-top: 5rem;
}
.profile-steps {
    gap: 16px;
}

.profile-steps-page-container {
    padding-right: 5rem;
    padding-left: 5rem;
}

.profile-steps-next-btn {
    background: linear-gradient(253.26deg, #1171d8 -20.35%, #3cc8eb 100%);
    backdrop-filter: blur(50px) brightness(150%);
    -webkit-backdrop-filter: blur(50px) brightness(150%);
    border-radius: 18px;
    height: 60px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    font-size: 24px;
    border: unset;
}

.profile-steps-next-btn:hover {
    background: linear-gradient(253.26deg, #3cc8eb -20.35%, #1171d8 100%);

}
.profile-steps-heading {
    color: rgba(0, 0, 0, 1);
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.6px;
}

.profile-steps-input {
    border: 1px solid;
    border-color: rgba(188, 188, 188, 1);
    border-radius: 18px;
    backdrop-filter: blur(50px) brightness(150%);
    -webkit-backdrop-filter: blur(50px) brightness(150%);
    padding: 12px 29px;
    letter-spacing: 0;
    line-height: 18px;
    color: rgba(56, 56, 56, 1);
    font-size: 18px;
    font-weight: 400;
}


.profile-steps-upload-wrapper {
    width: 264px;
}

.profile-steps-upload-card {
    background: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: #dadada;
    border-radius: 28px;
    width: 264px;
    height: 206px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    gap: 15px;
}

.profile-steps-upload-text {
    color: #292e34;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 16px;
}

.profile-steps-upload-btn {
    background: linear-gradient(90deg, #d851ef -71.45%, #9240ca 34.44%, #1877f2 105.2%);
    border-radius: 9px;
    box-shadow: 0px 3px 6px #00000029;
    height: 40px;
    padding: 2px 31px;
    width: 217px;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 16px;
    border: unset;
}

.profile-steps-upload-btn:hover {
    background: transparent;
    color: #d851ef;
    border: 1px solid #fff;
    border-top-color: #d851ef;
    border-left-color: #d851ef;
    border-bottom-color: #1877f2;
    border-right-color: #1877f2;
    box-shadow: 1px 0px 8px #d751ef86;
}

@media (max-width: 400px) {
    .profile-steps {
        flex-direction: column;
    }

    .profile-steps-next-btn {
        margin-bottom: 20%;
    }
}



@media (max-width: 350px) {
    .profile-steps-upload-btn {
        width: 100% !important;
        padding: 2px 3px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .profile-steps-upload-card {
        padding: 10px 10px;
        width: 100% !important;
        height: 100% !important;
    }

    .profile-steps-upload-wrapper {
        width: 100% !important;
    }

    .profile-steps-upload-text {
        font-size: 14px;
        font-weight: 500;
    }
}

@media (max-width: 320px) {
    .profile-steps-page-container {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.PhoneInputInput {
    border: initial;
    width: 100%;
}

/* @media (max-width: 300px) { 
    input[type="date"]::-webkit-calendar-picker-indicator { 
        margin: -15px;
    }
    px-5
} */  