.container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
}

.home-steps-bg {
    padding: 1em 3em 3em 3em;
    height: 100%;
    /* min-height: 1200px; */
    min-width: 375px;
    max-width: 1000px;
    background-color: #fafdff;
}

.home-container {
    align-items: flex-start;
    /* height: 131px; */
    width: 100%;
    margin: 40px 0 40px 0;
    padding: 20px 30px;
    border: 1px solid lightgray;
    background-color: #fff;
    border-radius: 10px;
}


.h4-home {
    margin: 0px !important;
    padding-bottom: 5px;
    color: #616990 !important;
    font-weight: 500 !important;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    text-align: left;
}

.h6-home {
    margin: 0px !important;
    color: #616990 !important;
    font-weight: 500 !important;
    width: 100%;
    padding: 10px 0px;
    text-align: left;
    /* text-transform: none !important; */
}