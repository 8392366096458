.presentation_input {
    width: 100%;
    margin: auto;

    .presentation_container {
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 5px rgba(0, 0, 0, 0.06);
        border-radius: 21px;
        padding: 20px;
    }

    input {
        width: 330px;
        padding: 0px 10px;
        margin: 0px;
        display: block;
        border: none;
        background: transparent;
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(270deg, #9934F0 -24.39%, #D318F2 -24.39%, #9437EF 42.15%, #425FEC 129.27%);
        border-image-slice: 1;
        border-radius: 3px;
        font-size: 1.2rem;
        font-weight: 500;
        background-image: linear-gradient(180deg, rgba(75, 219, 255, 1) 0%, rgba(0, 153, 255, 1) 67%);
        background-clip: text;
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    &::placeholder {
        color: #141414b6;
    }
}


.Typewriter__cursor {
    display: none !important;
}

.suggestion_container {
    cursor: text;
    min-height: 31px;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(270deg, #9934F0 -24.39%, #D318F2 -24.39%, #9437EF 42.15%, #425FEC 129.27%);
    ;
    border-image-slice: 1;
    border-radius: 3px;
}

.Typewriter__wrapper {
    display: flex;
    justify-content: center;
    min-width: 220px;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    font-weight: 500;
    background-image: linear-gradient(180deg, rgba(75, 219, 255, 1) 0%, rgba(0, 153, 255, 1) 67%);
    background-clip: text;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}
// #inline_height{
//     height: unset !important;
// }

@media (max-width : 600px) {
    .Typewriter__wrapper{
        min-width: 140px;
    }
    .presentation_input input{
        width: 210px;
    }
    
}
@media screen and (max-width: 500px) {
    .presentation_input input{
        width: 140px;
    }
    .suggestion_container{
        min-height: 19px !important;
    }
}

.css-max5g5-Control {
    cursor: text !important;
}


.css-1fdsijx-ValueContainer {
    border-bottom: 1px solid black !important;

}

.css-13cymwt-control {
    border: none !important;
    border-radius: 0 !important;
}

.css-1xc3v61-indicatorContainer,
.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.css-1jqq78o-placeholder {
    color: #141414b6 !important;
    font-weight: 600;
}

.presentation_name {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-grow: 1;
}

.height_for_presentation {
    height: calc(100vh);
    overflow: scroll;
}

// @media (max-width : 1200px) {
//     .height_for_presentation {

//         height: calc(100vh - 147px);
//     }

// }

// @media (max-width : 991px) {
//     .height_for_presentation {

//         height: calc(100vh - 82px);
//     }

// }

.presentation_input h4 {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
    color: black;
}

.presentation_name input {
    border: none;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
}

.slides_buttons {
    display: none;
}

.slides_tile:hover .slides_buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    z-index: 10;
   
}

.slides_buttons::before {
    content: '';
    height: 30px;
    width: 2.5px;
    border-radius: 5px;
    margin-right: 10px;
    background: #1877F2;
}

.slides_tile_input {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.slides_tile_item_grabbing:hover {
    background: #FFFFFF !important; 
    border: 1px solid #D318F2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slide_preview {
    display: flex;
    gap: 20px;
    align-items: center;
    height: calc(100vh - 250px);
    justify-content: space-between;
}

.slide_left {
    cursor: pointer;
}

.slide_right_item {
    height: 100%;
}

.slide_left_item {
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.2196078431);
    border-radius: 10px;
    padding: 10px;
    width: 100px;
    overflow: auto;
    white-space: nowrap;
}

.slide_left_item h4 {
    color: black;
    margin: 0;
}

.slide_left {
    width: 20%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
    height: 75vh;
    overflow: auto;
}

.slide_right {
    width: 80%;
    overflow: auto;
    height: 70vh;
    overflow: hidden;

}

.slide_right_item {
    background-color: #00000012;
    display: flex;
    gap: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .suggestion_container{
        min-height: 22px;
    }
}

@media (max-width:991px) {
    .slide_right {
        width: 100%;
    }
    .slide_right_item {
        flex-direction: column;
        justify-content: center;

        h2 {
            font-size: 25px;
        }
    }
    .presentation_name h4 {
        font-size: 1rem;
    }

}