.parent {
    h1 {
        text-align: center;
        font-size: 2em;
        font-weight: bolder;
    }
}

.button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .undo {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 1px solid #AFD4FF;
        border-radius: 10px;
        cursor: pointer;
    }

    button {
        width: auto;
        padding: 0 10px;
        height: 40px;
        border: 1px solid #AFD4FF;
        border-radius: 10px;
        background-color: white;
        margin-top: 0;
        font-weight: 600;
        font-size: 0.8rem;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    span {
        width: 80px;
        height: 40px;
        border: 1px solid #AFD4FF;
        border-radius: 10px;
        background-color: white;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        div {
            flex-grow: 1;
            justify-content: center;
            display: flex;
            align-items: center;
            height: 100%;
            align-self: center;
        }
    }

    input {
        background-color: none;
        width: 200px;
    }
}

.container {
    max-width: 1258px;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 20px;
    flex-wrap: wrap;
    margin: 20px auto;

    #canvasImage {
        width: 320px;
        height: 300px;
        object-fit: contain;
    }
}

.scribble_form {
    border: 1px solid #DDD;
    border-radius: 10px;
    padding: 6px;
    background: #fff;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        margin-bottom: 0;
        flex-grow: 1;
        border: none;
        padding: 5px;

        &::placeholder {
            font-weight: 700;
            font-size: small;
            color: #A1A1A1;
        }
    }

    button {
        margin-top: 0;
        background: linear-gradient(252deg, #0047FF 10.82%, #00B2FF 92.85%);
        color: white;
        font-weight: 700;
        border-radius: 8px;
        padding: 5px 15px;
        border: none;
        font-size: 0.9;
    }
}

@media (min-width:500px) {

    .container {
        flex-direction: row;


        >div {
            flex: 1;

            #canvasImage {
                width: 550px;
                height: 400px;
                object-fit: contain;
            }

            >div {
                width: 550px !important;
                height: 400px !important;
                margin: auto !important;
            }
        }
    }

}