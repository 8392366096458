.markDown {
    border: 1px solid #E3E3E3;
    overflow-x: auto;
    width: 100%;
    height: 500px;
    padding: 10px;
    word-wrap: break-word;
    background-color: rgb(235, 247, 255);
    ol, ul {
        padding: 0px 40px !important;
    }
}