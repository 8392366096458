.image-drop-container {
  display: flex;
  justify-content: center;
  background: #fefefe;
  border-radius: 25px;
  border: 3px dashed #e9e9e9;
  box-shadow: 0px 0px 46px 0px rgba(36, 0, 255, 0.1);
}
.image-drop-zone {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-drop-text {
  text-align: center;
  color: #3b6793;
  font-weight: 600;
  gap: 10px;
  padding: 5px;
}
.image-drop-text label {
  font-size: 18px;
}

.image-drop-text img {
  display: block;
  margin: 0 auto;

  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
}
.image-drop-zone.dragging {
  border-color: #2089ff;
  background-color: #e6f1ff;
  /* background-image: url('../../assets/img/drag-img-hover.gif');
  background-repeat: no-repeat;
  background-size: 16vh;
  background-position: top;
  transition: background-image 0.5s ease-in-out; */
}

.file-input-label {
  display: inline;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  color: #2089ff;
}
.file-input-label input {
  opacity: 0;
  display: none;
}

.upload-progress-bar {
  margin-top: 1.25rem;
  width: 100%;
  height: 1.875rem;
  border-radius: 0.625rem;
  background-color: lightgray;
  position: relative;
}

.progress-bar {
  height: 100%;
  border-radius: 0.625rem;
  background-color: blue;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.3s ease-in-out;
}
.hideu {
  display: none !important;
}
.showu {
  display: block !important;
}
.progress-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.progress-info button {
  margin-left: 0.625rem;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
}

.upload-complete {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: green;
  padding: 1.25rem;
  border-radius: 0.625rem;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.complete-message {
  color: white;
  font-size: 1.25rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* uploading */
.uploading-light {
  /* border-radius: 1.25rem; */
  /* border: 0.01rem solid #e3e7ed; */
  margin-top: 1rem;
  width: 80%;
  /* background-color: #ffffff; */
  position: relative;
  overflow: hidden;
  /* display: flex !important;
align-items: center;
justify-content: center; */
}
.uploading-gray {
  border-radius: 1.25rem 0rem 0rem 1.25rem;
  padding: 0.5rem;
  /* border: 0.01rem solid #c8d2de; */
  background-color: #f7fafd;
  display: flex;
  gap: 0.625rem;
  align-items: center;
}
.uploading-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.uploading-gray img {
  height: 6vh;
  width: 3vw;
}
.complete-gray {
  border-radius: 1.25rem 0rem 0rem 1.25rem;
  padding: 0.5rem;
  /* border: 0.01rem solid #c8d2de; */
  background-color: #f7fafd;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.complete-gray img {
  padding: 0rem 1rem;
  height: 3vh;
}
.completedu {
  color: #6586a7;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0rem 1rem;
}
.uploading {
  color: #6586a7;
  font-weight: 600;
  font-size: 14px;
  /* position: absolute; */
}
.uploading-time {
  color: #a7aeb6;
  font-weight: 300;
  font-size: 12px;
  min-width: 10rem;
  padding: 0 0.3rem;
  /* position: absolute; */
}

.progress {
  position: relative;
  background-color: rgba(255, 255, 255, 0) !important;
}

.progress-bar {
  height: 0.125rem;
  background-color: #a4cefe !important;
}
.buttons-uploading {
  position: absolute;
  top: 0px;
  right: 1rem;
}
/* .show {
  display: block;
  animation: fadeIn 1s;
} */

.hide {
  display: none;
  animation: fadeOut 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* responsive */

@media (max-width: 800px) {
  .image-drop-zone {
    width: 100%;
  }
  html {
    font-size: 80%;
  }
}

@media (max-width: 482px) {
  /* .image-drop-zone {
    height: 30vh;
    width: 60vw;
  } */
  html {
    font-size: 60%;
  }
}

/* for enhance .js  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */
.enhance-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /* background: #fefefe; */
  /* background: #d13b3b; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  /* box-shadow: 0px 0px 1.25rem 0.188rem #0f6ad417; */
  /* padding: 1.25rem; */
  /* margin-top: 3rem; */
  width: 62vw;
  /* height: 62vh; */
}
.enhance-container img {
  border-radius: 15px;
  /* height: 350px; */
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  /* margin-bottom: 1rem; */
  flex-shrink: 0;
  object-fit: contain;
  object-position: center top;
  max-height: 430px;
}
.enhance-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  height: 9vh;
}
.enhance-1 {
  border: none;
  padding: 0.438rem 1.688rem;
  transform: scale(0.9);
  border-radius: 1.875rem;
  background: linear-gradient(45deg, rgb(3, 3, 3) 0%, rgb(1, 1, 1) 100%);
  color: #ffffff !important;
  text-decoration: none !important;
}
.enhance-2 {
  border: none;
  padding: 0.438rem 1.688rem;
  transform: scale(0.9);
  border-radius: 1.875rem;
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff !important;
  text-decoration: none !important;
  font-size: 20px;
}
.enhance-2:hover,
.enhance-1:hover {
  transform: scale(1);
  border-radius: 1.875rem;
  background-color: var(--text-color);
  opacity: 1;
  color: #fff;
  transition: all 0.5s ease;
}
.enhance-2:disabled {
  filter: grayscale(1) !important;
}
/* responsive */
@media (min-width: 600px) {
  .before-after-slider img {
    height: 65vh;
  }
}

@media (max-width: 592px) {
  .enhance-container {
    width: 80%;
  }
}
@media (max-width: 280px) {
  .enhance-container {
    /* width: 75vw; */
  }
}

/* beforeAfter-container */

.beforeAfter-container {
  touch-action: none;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 5px;
  border-radius: 1.25rem;
  padding: 0px 1.25rem 1.25rem 1.25rem;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.ehanced-img {
  flex-shrink: 0;
  object-fit: contain;
  object-position: center top;
  /* max-height: 88%; */
  max-width: 500px;
  width: 100%;
  max-height: 450px;
  border-radius: 1rem;
}
.beforeAfter-container h1 {
  font-size: 1.3rem;
  color: rgba(128, 128, 128, 0.787);
  font-weight: 700;
  margin: 0rem;
  margin-bottom: 0.3rem;
}
.audio_result {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.ehancing-images-container {
  width: 37vw !important;
  height: 51vh !important;
}
.before-after-slider {
  direction: ltr !important;
}
.before-after-slider img {
  height: 60vh;
  object-fit: contain;
}
.before-after-wrap img {
  width: 37vw !important;
  height: 42vh;
}
.before-after-wrap img.before-after-seperator-img {
  width: 3.375rem !important;
}
.beforeafter-gray {
  border-radius: 1.25rem 0rem 0rem 1.25rem;
  padding: 1rem;
  /* background-color: #F7FAFD; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.beforeafter-gray img {
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
  flex-shrink: 0;
  object-fit: contain;
  object-position: center top;
  max-height: 100%;
}
.download-btn {
  min-width: 197px;
  border: none;
  padding: 0.438rem 1.688rem;
  transform: scale(0.9);
  border-radius: 12px;
  border: 2px solid #98e0ff;
  background: #fff;
  text-decoration: none !important;
  font-size: 20px;
  display: "flex";
  align-items: "center";
  justify-content: "space-around";
  font-size: "medium";
  font-weight: "700";
}
.download-btn:hover {
  transform: scale(1);
  opacity: 1;
  transition: all 0.5s ease;
}

.newupload {
  /* margin-top: 1rem; */
  min-width: 197px;
  border: none;
  padding: 0.438rem 1.688rem;
  transform: scale(0.9);
  border-radius: 12px;
  border: 2px solid #98e0ff;
  background: #fff;
  text-decoration: none !important;
  font-size: 20px;
  display: "flex";
  align-items: "center";
  justify-content: "space-around";
  font-size: "medium";
  font-weight: "700";
}
.newupload:hover {
  transform: scale(1);
  opacity: 1;
  transition: all 0.5s ease;
}
.before-after-slider img {
  flex-shrink: 0;
  object-fit: contain;
  object-position: center top;
  max-height: 100%;
}

.before-after-slider__delimiter {
  width: 3px !important;
}
.before-after-slider__delimiter-icon {
  background-image: url("assets/img/newDesign/code.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.5rem;
  background-position: center;
  position: relative;
}
.before-after-slider__delimiter::before {
  content: "Before";
  position: absolute;
  top: 3%;
  transform: translateY(-50%);
  background: #0a0a0a85;
  padding: 0.3rem 0.6rem;
  color: #fbfafa;
  font-size: 1rem;
  border-radius: 31rem;
}
.before-after-slider__delimiter::after {
  content: "After";
  position: absolute;
  top: 3%;
  transform: translateY(-50%);
  background: #0a0a0a85;
  padding: 0.3rem 0.6rem;
  color: #fbfafa;
  font-size: 1rem;
  border-radius: 31rem;
}
.before-after-slider__delimiter:before {
  right: 2rem; /* Position the "before" text on the left side */
}

.before-after-slider__delimiter:after {
  left: 2rem; /* Position the "after" text on the right side */
}

/* responsive */

@media (max-width: 500px) {
  .beforeAfter-container {
    width: 100%;
  }
  .audio_result {
    flex-direction: column;
  }
}

#wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
#wrap canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 500px;
  margin: -250px 0 0 -250px;
  -outline: 1px solid #fff;
}
.bg_remover_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}
.rotate_buttons {
  position: relative;
  width: 448px;
  justify-content: flex-end;
  z-index: 10;
  left: 50%;
  display: flex;
  gap: 10px;
  transform: translate(-50%, -50%);
}
.rotate_buttons img {
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 3px;
  border: 0.2px solid #00000029;
}

.rotated_image {
  max-width: 500px;
  min-height: 450px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .download-btn {
    min-width: 160px;
  }
  .newupload {
    min-width: 160px;
  }
  .rotate_buttons {
    max-width: 400px;
    width: unset;
  }
}

@media (min-width: 900px) {
  .bg_remover_background {
    width: 100%;
    /* height: 100%; */
  }
}

@media (max-width: 670px) {
  .redesign_image {
    flex-direction: column-reverse;
  }
  .redesign_image_item {
    flex-direction: column-reverse;
    margin-top: 45px;
  }
  .redesign_image_item_item {
    flex-direction: column-reverse;
  }
}

.bg_remover_background {
  background-image: url("assets/img/newDesign/BG.png");
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  max-width: 90%;
  object-fit: cover;
  max-height: 500px;
  margin: 20px auto;
}
.inpainting_input {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
}
.inpainting_input input:focus,
.textarea_text_to_speech textarea:focus {
  border-color: #00b2ff;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.inpainting_input input,
textarea {
  flex: 1;
  border-radius: 10px;
  border: 1px solid #00b2ff;
  padding: 10px;
  margin: 10px 0;
  min-width: 300px;
}
.continuation {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
}
.item {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.continuation_item {
  display: inline;
}

@media (max-width: 599px) {
  .inpainting_input {
    gap: 5px;
  }
}

.image-drop-support-text {
  font-size: 16px;
}
.image-drop-support-drop {
  margin-top: 20px;
  color: #a6c0cf;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 10px;
}
.image-drop-support-text {
  color: #7f7f7f;
  font-weight: 500;
  font-size: 0.8rem;
}

.image-drop-support-button {
  background: #00a3ff;
  box-shadow: 0px 0px 6px 0px rgba(7, 77, 255, 0.25);
  border-radius: 5rem;
  padding: 0.7rem 2rem;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
