.modal-content {
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  border: none !important;
}

.swiper-pagination {
  position: relative !important;
  top: 8px !important;
}

.swiper-pagination-bullet-active {
  background: #455a64 !important;
}

.MuiSlider-thumb {
  width: 25px !important;
  height: 25px !important;
  color: black !important;
  border-radius: 50% !important;
  background: white !important;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.12) !important;
  /* -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}
.MuiSlider-thumb {
  background-image: url("./assets/img/downgrade/thumb.svg") !important;
  background-position: center !important;
  background-size: 14px !important;
  background-repeat: no-repeat !important;
}

/* .css-nnid7-MuiSlider-valueLabel {
  transform: rotate(180deg) !important;
  background: #1877f2 !important;
  color: white !important;
  margin-top: 15px !important;
}
.css-nnid7-MuiSlider-valueLabel span {
  transform: rotate(180deg) !important;
} */

.MuiSlider-rail {
  opacity: 1 !important;
  background: linear-gradient(
    270deg,
    #1877f2 4.26%,
    #3cc8eb 103.62%
  ) !important;
  border-radius: 3px !important;
  border: none !important;
}

.MuiSlider-root {
  height: 6px !important;
}
