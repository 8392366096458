* {
  margin: 0;
  padding: 0;
}

.card {
  width: 100%;
  aspect-ratio: cac(16/9);
  max-width: 350px;
  border-radius: 10px;
  background-image: linear-gradient(to right,
      rgba(152, 92, 250, 1),
      rgba(69, 140, 249, 1),
      rgba(59, 195, 240, 1));
}

.chip-image {
  padding: 12px;
}

.chip-image img {
  width: 50px;
}

.card-details {
  padding: 0px 15px;
}

.card-number {
  font-size: 25px;
  color: #fff;
}

.date {
  font-size: 15px;
  padding: 4px 0px;
  color: #fff;
}

.picture {
  display: flex;
  justify-content: end;
  padding: 0px 24px;
}

.picture img {
  width: 40px;
}