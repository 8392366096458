.Loading_v2_container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    gap: 20px;
    flex-direction: column;

    h5 {
        margin: 0;
        font-weight: 700;
        color: #091863;
    }

}



@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 60px 0;
    }
}

// @keyframes auto-progress {
//     0% {
//         width: 0%;
//     }

//     100% {
//         width: 100%;
//     }
// }
.progress_bar_wrapper {
    width: 80%;
    margin: 15px auto;
}

.progress_bar_v2 {
    overflow: hidden;
    height: 25px;
    width: 450px;
    border-radius: 20px;
    border: 3.5px solid white;
    filter: drop-shadow(0px 0px 21px rgba(0, 0, 0, 0.25));
}

.progress_bar_text {
    color: #091863;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

}

.stripes {
    background-size: 30px 30px;
    background-image: linear-gradient(115deg,
            rgba(173, 222, 255, .15) 30%,
            transparent 25%,
            transparent 50%,
            rgba(125, 181, 255, .15) 100%,
            transparent 75%);
}

.stripes.animated {
    animation: animate-stripes 0.6s linear infinite;
}

.stripes.animated.slower {
    animation-duration: 2.25s;
}

.stripes.reverse {
    animation-direction: reverse;
}

.progress_bar_v2_inner {
    display: block;
    height: 100%;
    width: 0%;
    background: linear-gradient(90deg, #6100FF 2%, #00E9FF 100%);
    border-radius: 3px;
    position: relative;
    animation: auto-progress 10s infinite linear;
}

@media (max-width: 768px) {
    .progress_bar_v2 {
        width: 300px;
    }
}