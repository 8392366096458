.revamped-profile {
  padding: 0 40px 40px;
  padding-top: 20px;
  position: relative;
  z-index: 3;
  height: calc(100vh - 50px);
}
.businessProfile {
  position: relative;
}
/* for styling the profile page */
.title-area {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.editbtn {
  background: none;
  border: none;
  margin-left: auto;
}
.hredit {
  display: block;
  width: 73.5%;
  position: relative;
  top: -12px;
}
.hrplan {
  width: 98%;
  margin-top: 1px;
}
.my-usage {
  color: #455a64 !important;
  text-decoration: none !important;
  /* border: 1px solid #455a64;
    border-radius: 50px; */
}
.plan-content {
  padding-left: 14px;
  padding-right: 17px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.my-usage h5 {
  border: 1px solid #455a6475;
  border-radius: 50px;
  padding: 5px 23px;
}
.edit-profile {
  margin-left: auto;
  padding: 7px 27px;
  transform: scale(0.9);
  border-radius: 15px;
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff !important;
  text-decoration: none !important;
}
.edit-profile:hover {
  border-radius: 30px;
  background-color: var(--text-color);
  opacity: 1;
  color: #fff;
  transform: scale(1);
  transition: all 0.5s ease;
}

/* ends here */
/* styling for user plan */
.plan-box {
  padding: 20px;
  margin: 20px;
  border-radius: 38px;
  box-shadow: 0px 0px 20px 3px #0f6ad417;
}
.plan-box-second {
  padding: 0px !important;
  /* margin: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 20px 3px #0f6ad417;
  flex-direction: row;
  /* padding: 20px 15px 0px 15px; */
  /* width: 75%; */
}
.plan-box-second .myplan-title {
  margin: 0px;
  padding: 0px;
}
.myplan-title {
  padding-top: 21px !important;
}
.plan-head {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
}
.newHeader {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.manage-plan-link {
  padding-top: 21px;
  margin-left: auto;
}
.manage-plan {
  /* width: 20%; */
  margin-left: auto;
  padding: 7px 27px;
  transform: scale(0.9);
  border-radius: 15px;
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff !important;
  text-decoration: none !important;
}
.manage-plan:hover {
  transform: scale(1);
  border-radius: 30px;
  background-color: var(--text-color);
  opacity: 1;
  color: #fff;
  transition: all 0.5s ease;
}

/* plan ends here */

.revamped-profile .title-area {
  flex-direction: row;
  padding: 20px 15px 0px 15px;
  /* width: 75%; */
  max-width: 1200px;
}

.revamped-profile .title-area .title {
  display: flex;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: black;
  text-transform: uppercase;
}

.revamped-profile button:hover .edit-profile {
  color: #1877f2;
  text-align: right;
  text-decoration: underline;
}

.revamped-profile button .edit-profile {
  color: #1877f2;
  text-align: right;
}

.revamped-profile button:hover .edit-payment {
  color: #1877f2;
  text-align: right;
  text-decoration: underline;
}

.revamped-profile button .edit-payment {
  color: #1877f2;
  text-align: right;
}

.revamped-profile a:hover .my-usage {
  color: #1877f2;
  text-decoration: underline;
}

.revamped-profile a .my-usage {
  color: #1877f2;
}

.revamped-profile a:hover .upgrade-plan {
  color: #1877f2;
  text-decoration: underline;
}

.revamped-profile a .upgrade-plan {
  color: #1877f2;
  text-align: right;
  margin-bottom: 10px;
}

.revamped-profile button:hover .cancel-plan {
  color: #1877f2;
  text-decoration: underline;
}

.revamped-profile button .cancel-plan {
  color: #1877f2;
  text-align: right;
  margin-bottom: 10px;
}

/* .revamped-profile .upgrade-plan {
    color: #1877f2;
    text-decoration: underline;
    text-align: right;
} */

.revamped-profile .card-body {
  /* -ms-flex: 1 1 auto; */
  /* flex: 1 1 auto; */
  padding: 0 2.5rem 2.5rem;
  /* margin-top: 50px; */
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .revamped-profile .profile-form {
    padding: 1rem;
}

.revamped-profile .pro-img {
    margin-top: -60px;
} */
.revamped-profile .card-body .img-styling {
  width: 128px;
  height: 128px;
  position: relative;
  display: flex;
}

.revamped-profile .card-body .img-styling img {
  width: 128px;
  height: 128px;
  /* -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  border-radius: 100%;
  border: 4px solid #2daae4;
  position: relative;
  display: flex;
}

.revamped-profile .card-body .img-styling .edit-icon {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 65%;
  right: 10%;
  border: 2px solid #2daae4;
  border-radius: 30px;
  height: 28px;
  width: 28px;
  background-color: #2daae4;
}

.card {
  height: 100%;
  border-radius: 25px;
  max-width: 800px;
}

.form-label {
  color: black !important;
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #2daae4;
  font-size: 20px;
}

.padding {
  padding: 3rem !important;
  margin-left: 200px;
}

.words {
  position: absolute;
  top: 50px;
  left: 10%;
  color: white;
  font-size: 18px;
  /* font-family: 'Poppins' */
}

.photos {
  position: absolute;
  top: 50px;
  right: 10%;
  color: white;
  font-size: 18px;
  /* font-family: 'Poppins' */
}

.card-img-top {
  height: 100px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding: 20px 20px;
  /* background: #2DAAE4; */
  background: linear-gradient(
    to right,
    rgba(60, 200, 235, 1) 0%,
    rgba(17, 113, 216, 1) 100%
  );
  align-items: center;
}

.card-no-border .card {
  border-color: #d7dfe3;
  border-radius: 4px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.profile-form {
  padding: 1rem;
}

.pro-img {
  margin-top: -80px;
}

.little-profile .pro-img img {
  width: 128px;
  height: 128px;
  /* -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  border-radius: 100%;
  border: 4px solid #2daae4;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

.btn-rounded.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

html body .m-t-10 {
  margin-top: 10px;
}

.btn-primary,
.btn-primary.disabled {
  background: #7460ee;
  border: 1px solid #7460ee;
  -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14),
    0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14),
    0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}

.m-t-20 {
  margin-top: 20px;
}

.text-center {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #455a64;
  /* font-family: "Poppins", sans-serif; */
  font-weight: 400;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.prof-btn {
  background: linear-gradient(
    to right,
    rgba(20, 119, 217, 1),
    rgba(60, 200, 235, 1)
  );
  color: white !important;
  border-radius: 10px;
  font-weight: 700;
  border: none;
  padding: 10px 15px;
  outline: none;
  background-color: transparent;
}

.prof-btn:hover {
  background: linear-gradient(
    to right,
    rgba(20, 119, 217, 1),
    rgba(60, 200, 235, 1)
  );
  color: white !important;
  border-radius: 10px;
  font-weight: 700;
  border: none;
  padding: 10px 15px;
  outline: none;
  background-color: transparent;
}

.profile-label {
  font-weight: 400;
  font-size: 16px;
}

.profile-desc {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 1em;
}

@media (max-width: 992px) {
  .plan-box {
    border-radius: 25px !important;
  }
  .plan-box-second {
    border-radius: 25px !important;
  }
  .revamped-profile .title-area .title {
    font-size: 20px;
  }

  .revamped-profile .title-area {
    width: 100%;
  }
  .revamped-profile {
    height: 100% !important;
  }
}

@media (max-width: 500px) {
  .business_table {
    display: block;
  }
  .modal-dialog .modal-content {
    width: 95% !important;
    margin: 10px !important;
  }
  .plan-content {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column !important;
    align-items: baseline !important;
  }
  .plan-head {
    align-items: unset !important;
    padding: 0 !important;
  }
  .plan-box {
    padding: 0 !important;
  }
  .plan-box-second {
    padding: 0 !important;
  }
  .manage-plan {
    padding: 7px 15px;
    /* font-size: 12px !important; */
  }
  .revamped-profile {
    padding: 0 20px 20px !important;
  }
  .revamped-profile {
    height: 100% !important;
  }
}

/* @media (min-width: 2200px) {
    .revamped-profile .title-area {
        width: 70%;
    }
} */

/* REVAMPED USAGE CSS */
.revamped-usage .title {
  /* font-family: "Poppins"; */
  color: black;
  font-size: 1.75em;
  font-weight: 600;
  margin-bottom: 30px;
}

.revamped-usage .info_box {
  padding: 1.75em 1.25em;
  border: 0px;
  border-radius: 0px;
}

/* REVAMPED PAYMENT SECTION CSS */
.revamped-payment {
  display: flex;
  flex-direction: row;
}

.revamped-payment .payment-btn {
  color: #1877f2;
}

.revamped-payment .payment-btn:hover {
  text-decoration: underline;
}

.react-tel-input .form-control {
  border: none !important;
  font-size: 1rem !important;
  width: 100% !important;
}
.react-tel-input {
  margin-top: -7px !important;
}
.react-tel-input .flag-dropdown {
  background-color: unset !important;
  border: none !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.non_enterprise_users {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.business_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  padding: 0 15px !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06) !important;
}
.business_card:first-child {
  padding: 15px !important;
}
.business_card button {
  background: linear-gradient(90deg, #5f94dc 0%, #81c9e9 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  border: none;
  color: white;
  padding: 5px 20px;
  font-size: 0.8rem;
  min-width: 73px;
  margin: 5px 0;
}
.business_card button:hover {
  transform: scale(1);
  transition: all 0.5s ease;
  opacity: 1;
}

.business_card h4 {
  font-size: 0.8em;
  line-height: unset;
  font-weight: 400;
  color: black;
  margin: 0px auto;
}
.business_card h2 {
  font-weight: 700;
  color: black;
  margin: 10px 0;
  font-size: 1.4rem;
}
.plan_billing_card {
  padding: 20px;
  border-radius: 10px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06) !important;
}
.plan_billing {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.plan_billing h4 {
  margin: 0;
  font-weight: 700;
  font-size: 1.1rem;
  color: black;
}
.plan_billing button {
  background: linear-gradient(90deg, #5f94dc 0%, #81c9e9 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  border: none;
  color: white;
  padding: 5px 20px;
  /* min-width: 155px; */
}
.plan_billing_grid {
  display: flex;
  justify-content: space-between;
  text-align: left;
  color: black;
}
.plan_billing_grid h5 {
  margin: 0;
}
.plan_billing_items {
  display: flex;
  align-items: center;
}
.plan_billing_items h5 {
  font-size: 0.7em;
  margin-left: 5px;
  color: black;
}

.business_modal_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 !important;
}
.business_modal_title h4 {
  margin: 0;
  font-weight: 600;
  color: black;
  font-size: 1.3rem;
}
.business_modal_title h5 {
  font-weight: 200;
  color: black;
  margin: 0;
}
.business_modal_body input {
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 5px 10px;
}
.business_modal_body input::placeholder {
  opacity: 0.2;
}
.business_modal_body div {
  gap: 10px;
  padding: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  background-color: #f2fcf5;
  border-radius: 10px;
}

.business_modal_body div h5 {
  margin: 0;
  color: #268e7b;
  font-size: 0.8rem;
}
.business_modal_body div:last-child {
  background-color: #fcf2f2;
}
.business_modal_body div:last-child h5 {
  color: #8e2b26;
}

.business_modal_footer button {
  background: linear-gradient(90deg, #5f94dc 0%, #81c9e9 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  border: none;
  color: white;
  padding: 5px 20px;
  min-width: 100px;
  margin: 5px auto;
  display: block;
}
