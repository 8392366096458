.close_button{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    color: black;
    position: absolute;
    display: flex;
    top: 15px;  
    z-index: 5;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upgrade_modal_plan {
    border-radius: 15px;
    background: linear-gradient(360deg, rgba(252, 252, 252, 0.00) 75%, rgba(0, 255, 209, 0.28) 100%);
    font-family: Montserrat;
}

.modal_cards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 20px 20px 20px;
    margin: 0 20px;
}

.link_upgrade {
    color: #0E02D9;
    text-decoration: underline;
    color: #0E02D9;
    text-decoration: underline;
    width: 90%;
    display: block;
}

.modal_plan_card {
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.12);
    padding: 45px;

    h2 {
        margin: 0 0 25px 0;
        font-weight: 700;
        font-size: 2rem;
        color: black;
    }

    h5 {
        font-size: 0.83rem;
        font-weight: 500;
        color: black;
    }

    p {
        font-weight: 700;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    span {
        h3 {
            margin-bottom: 0;
            font-weight: 700;
            color: black;
        }
    }

    button {
        border-radius: 8px;
        background: linear-gradient(225deg, #06F 0%, #00B3FF 100%);
        color: white;
        border: none;
        padding: 5px 50px;
        font-weight: 600;

        &:hover {
            background: white;
            border: 1px solid #06F;
            color: #06F;
        }
    }
}

.upgrade_modal_header {
    text-align: center;

    h1 {
        color: black;
        font-weight: 700;
        margin: 5px 0;
        font-size: 2rem;
    }

    h5 {
        font-size: 1rem;
        margin: 10px auto;
        max-width: 500px;
        font-weight: 400;
    }
}

@media (max-width : 991px) {
    .modal_cards {
        flex-direction: column;
        margin: auto;
        max-width: 350px;
    }

    .modal_plan_card h5 {
        font-size: 1.5rem;
    }

}

@media (max-width: 450px) {
    .modal_plan_card {
        button {
            padding: 10px 30px;
        }
    }

}

@media (max-width:500px) {
    .modal_cards {
        padding: unset;
        margin: auto;
        max-width: 300px;
    }

}