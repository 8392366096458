.media_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    margin-top: 5%;
    padding-bottom: 5%;
}

.height_media_section {
    height: calc(100vh - 129.19px);
    overflow: auto;
}

.media_section_card {
    text-align: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    cursor: pointer;
    max-width: 350px;

    img {
        object-fit: cover;
        height: 180px;
        width: 100%;
    }

    h2 {
        margin-top: 0;
        color: black;
        font-weight: 700;
        font-size: 1.2rem;
    }

    h5 {
        color: #4C4F68;
        font-weight: 600;
        font-size: 0.9rem;
    }

    div {
        padding: 20px;
    }
}

.media_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 0;
    position: relative;
    width: 100%;
}

.media_back {
    position: absolute;
    background-color: white;
    border: 1px solid #A1A1A1;
    padding: 0.2rem 1rem;
    border-radius: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
    color: #A3A3A3;
    margin-top: 0;
}

.media_heading {
    font-weight: 600;
    text-align: center;
    margin: auto;
    color: #6A7C85;
}

.image_gen_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    gap: 50px;
}

.right_image_media {
    object-fit: cover;
    max-width: 600px;
    height: auto;
}

.change_background_form {
    display: flex;
    padding: 5px;
    border-radius: 11px;
    border: 1px solid #8C8C8C;
    background: #FFF;

    input {
        border: none;
        outline: none;
        padding: 0 10px;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 600;
        flex-grow: 1;

        &::placeholder {
            color: #A1A1A1;
            font-weight: 800;
        }
    }

    button {
        background: linear-gradient(259deg, #0057FF -3.79%, #00B2FF 88.21%);
        border-radius: 10px;
        border: none;
        color: white;
        font-weight: 600;
        padding: 0.5rem 1.5rem;
    }
}

@media (max-width: 1400px) {
    .right_image_media {
        max-width: 400px;
    }

}

@media (max-width: 1200px) {
    .right_image_media {
        max-width: 350px;
    }
}

@media (max-width: 1100px) {
    .image_gen_container {
        justify-content: center;
        padding: 10px;

    }

    .scribble_wrap {
        flex-wrap: wrap;
    }


    .right_image_media {
        display: none;
    }
}

@media (max-width:600px) {
    .image_gen_container {
        gap: 10px;
    }

    .media_heading {
        font-size: 2rem;
    }
}

.text_to_video {
    flex-direction: column;
}
.text_to_video input{
    width: 100%;
}