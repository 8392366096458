@media screen and (max-width: 700px) {
  label {
    table {
      min-height: 500px;
      overflow-x: scroll !important;
      width: 100%;

      tbody {
        min-height: 500px;
        overflow-x: scroll !important;
        width: 100%;
      }
    }
  }
}
