.logo_header_wrapper {
  h4 {
    color: white;
  }
}

.dropdown {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 0 0 3px !important;

  img {
    border: 2px solid white;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
  }
  h5 {
    color: white;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
  }
}



@media (max-width:"550px") {
  .dropdown {
    gap: 0px !important;
    padding: 0 0 0 0px !important;
  }
  .araby {
    display: none !important;
  }

}