.main_case {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: #fafdff;
}
.document-left {
  border-right: 1px solid #e2e2e2;
  padding: 15px 10px;
}

.document-left div {
  margin-bottom: 26px;
}

.document-forms {
  border-radius: 10px !important;
}

.document-forms:focus {
  border: 2px solid #3ac5ea !important;
}

.doc_output_number {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  gap: 5px;
  flex-wrap: wrap;
}

.doc_output_number button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  min-width: 44px;
  min-height: 41px;
  background-color: #fff;
  border: unset;
  border-radius: 6px;
  box-shadow: 1px 0px 8px #80808030;
}

.doc_output_number button:hover {
  outline: 2px solid #4472f16c;
}

.ql-container.ql-snow {
  border: none !important;
}

@media screen and (min-width: 991px) {
  .ql-toolbar.ql-snow {
    width: 700px !important;
  }
}
@media screen and (max-width: 991px) {
  /* .ql-snow .ql-picker-label {
    width: 40px !important;
  }
  .ql-snow .ql-picker.ql-header {
    width: 40px !important;
  }  */
  .ql-editor {
    padding: 0 !important;
  }

  .ql-toolbar.ql-snow .ql-picker-label:nth-child(1) {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
}

.ql-toolbar.ql-snow {
  margin: auto !important;
  border-radius: 10px !important;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}
.ql-editor p,
.ql-editor ol,
.ql-editor li {
  font-size: 16px !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0.3rem !important;
  margin-right: 1.5rem !important;
}

/* .document-footer{
    position: absolute;
    background: rgba(143, 206, 243, 0.07);    bottom: 0;
    width: 100%;
}
.document-footer-input{
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.122));
    margin: 30px auto;
    width: 90%;
    border-radius: 13px !important;
} */

.document-save-image {
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 25px;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 17px !important;
  color: rgb(0, 0, 0) !important;
  font-family: "Poppins";
  font-style: normal !important;
}

.ql-snow .ql-editor {
  min-height: 50vh !important;
  height: 100% !important;
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: unset !important;
  display: table;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  margin-top: 0px !important;
}

#toolbar span button {
  padding: 3px !important;
  margin: 0 3px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#toolbar span button:hover {
  background: #d5d5d5 !important;
  border-radius: 5px !important;
}

.toolButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.toolButton button {
  background: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin: 0 3px;
}

.aiTools {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.aiTools button {
  background: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin: 0 3px;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  /* top: 310px !important; */
  border-radius: 5px !important;
  border: 0.5px solid #d3d3d3 !important;
  background: #fff !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08) !important;
  min-width: 250px !important;
}

.ql-snow .ql-editor h1 {
  color: black !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.ql-snow .ql-editor h2 {
  color: black !important;
  font-size: 25px !important;
  font-weight: 500 !important;
}

.ql-snow .ql-editor h3 {
  color: black !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.ql-snow .ql-editor strong {
  font-weight: 900 !important;
}

.toolButton button:hover {
  background: #d5d5d5 !important;
  border-radius: 5px !important;
}

.onActiveTools {
  background: #d5d5d5 !important;
  border-radius: 5px !important;
}

.progress-document {
  background: #1877f2;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 5px;
  border-radius: 10px;
}

.write-me {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.write-me-div input {
  margin-bottom: 0 !important;
  border: none;
}

.write-me-div select {
  border-radius: 5px;
  background: #f4fcff;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.write-me p {
  margin: 0 !important;
}

.write-me div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.write-me .div button {
  border-radius: 10px;
  border: none;
  background: #f4fcff;
  padding: 5px;
  margin-top: 0 !important;
}

.write-me-div > button {
  border: none;
  background: #1877f2;
  margin: 0;
  color: white;
  padding: 5px;
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 1rem;
}

.ql-editor {
  white-space: pre-wrap !important;
}

.ai-window {
  z-index: 1000;
  position: fixed;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #ececec;
  background: #fff;
  padding: 10px 15px 0 15px;
  overflow: auto;
  margin: 10px 0;
}

.ai-window-buttons {
  justify-content: flex-start;
  gap: 10px;
  position: sticky;
  margin-top: 10px;
  flex-grow: 2;
  bottom: 0px;
  display: flex;
  background: white;
  padding-bottom: 10px;
}

.ai-window button {
  border: none;
  background-color: #1877f2;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
}

.ai-window-response {
  padding: 10px !important;
  border-radius: 7px !important;
  border: 1px solid #ececec !important;
  background: #fff !important;
  margin-top: 10px !important;
  font-size: 0.9rem !important;
  color: black !important;
  white-space: pre-wrap !important;
}

.ai-window-response:hover {
  border-color: #3cc8eb !important;
}

.ai_tools_opener {
  position: sticky;
  top: 0px;
  background: white;
  display: inline;
  border-radius: 5px;
}

/* .ql-editor {
  direction: rtl !important;
  text-align: right !important;
} */

.document_name:hover {
  border-color: #00b2ff;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  padding: 5px;
}
.document_name {
  min-width: 150px !important;
}

.document_name:focus {
  background-color: rgba(13, 109, 253, 0.078);
  padding: 5px;
  border-radius: 2px !important;
}
.document_name_static:hover {
  cursor: text;
  border-color: #00b2ff;
  outline: 0 !important;
  padding: 5px;
  box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.25) !important;
}
.document_name_static {
  min-width: 150px !important;
  border-radius: 2px;
}

.bounce_tool {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.MuiTooltip-arrow {
  color: #000000 !important;
}

.MuiTooltip-tooltip {
  color: #ffffff !important;
  font-size: 0.8rem !important;
  background: #000000 !important;
  border-radius: 10px !important;
  font-family: "Tajawal" !important;
}

.button_write {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, -3px, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 2px, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 1px, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, -2px, 0);
  }
  /* Adding vertical translations */
  45%,
  55% {
    transform: translate3d(0, -3px, 0);
  }
  75% {
    transform: translate3d(0, 1px, 0);
  }
}

/* .arabicFont_editor {
  font-family: "Tajawal" !important;
  direction: rtl !important;
  text-align: right !important;
}

.englishFont_editor {
  font-family: "Poppins" !important;
  direction: ltr !important;
  text-align: left !important;
} */

.tooltip1_container {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(24, 119, 242, 0.12);
  top: 68px;
  display: flex;
  flex-direction: column;
  padding: 10px !important;
  border-radius: 10px;
  width: 270px;
  left: -70px;
}

.tooltip_button {
  align-self: flex-end !important;
  padding: 5px 10px !important;
  border-radius: 8px !important;
  background: #6b5af6 !important;
}

.tooltip2_container {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(24, 119, 242, 0.12);
  top: 74px;
  display: flex;
  flex-direction: column;
  padding: 10px !important;
  border-radius: 10px;
  width: 250px;
  right: 350px;
}

.tooltip_button2 {
  align-self: flex-end !important;
  padding: 5px 10px !important;
  border-radius: 8px !important;
  background: #6b5af6 !important;
  border: none;
  color: white;
}

.trian {
  width: 20px;
  height: 20px;
  background: #fff;
  /* box-shadow: 0px 0px 20px rgba(24, 119, 242, 0.12); */
  position: absolute;
  transform: rotate(45deg);
  top: -15px;
  left: 350%;
}
.trian2 {
  width: 20px;
  height: 20px;
  background: #fff;
  /* box-shadow: 0px 0px 20px rgba(24, 119, 242, 0.12); */
  position: absolute;
  transform: rotate(45deg);
  top: -15px;
  left: 150%;
}

.animated-gradient {
  height: 2px;
  background: repeating-linear-gradient(to right, #3cc8eb 0%, rgb(255, 255, 255) 50%, #3cc8eb 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
