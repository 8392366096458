.chatBot_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.chatBot_left {
    padding: 10px;
    flex-basis: 65%;
    height: 100%;
    box-shadow: 4px 0 10px -5px #888;
    position: relative;


    .chatBot_form {
        position: absolute;
        border: 1px solid #ccc;
        padding: 10px;
        display: flex;
        justify-content: center;
        width: 80%;
        border-radius: 10px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 5px;
        background-color: white;

        input {
            width: 100%;
            border: none;
            outline: none;
            margin-bottom: 0;
        }

        button {
            border: none;
            outline: none;
            background-color: #746BF9;
            cursor: pointer;
            margin-top: 0;
            padding: 5px 20px;
            border-radius: 10px;
            color: white;
        }
    }
}

.chatbot_right {
    flex-basis: 35%;
    height: 100%;

}

@media screen and (max-width: 768px) {
    .chatBot_left {
        flex-basis: 100%;
        box-shadow: none;
    }

    .chatbot_right {
        display: none;
    }

}

.chatBot_left_message {
    padding: 8px;
    border-radius: 10px;
    background-color: #E6F8FF;
    margin: 5px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.chatBot_left_message_container {
    height: 88%;
    overflow: auto;
}

.chatBot_left_message:nth-child(odd) {
    background-color: #CAF2FF;
}