.thank-you-modal .modal-content {
    border-radius: 30px;
    padding: 0px !important;
    border: unset;
    /* min-width: 450px; */
}

.thank-you-modal .modal.show .modal-dialog {
    transform: none !important;
}
.modalImgWrapper {
    width: 100%;
}
.btn-close {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}
.thank-you-modal .modal-content .modal-header {
    height: 30px;
    width: 100%;
    margin-top: 10px;
    padding: 10px !important;
    border-radius: 15px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.thank-you-modal .modal-content .modal-body {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.thank-you-modal .btn.common-btn-modal {
    padding: 10px;
    transform: scale(0.9);
    border-radius: 15px;
    background: rgb(17, 113, 216);
    background: linear-gradient(45deg, rgba(60, 200, 235, 1) 0%, rgba(17, 113, 216, 1) 100%);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 800;
    border: unset;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    margin-bottom: 20px;
}

.thank-you-modal .blue-gradient-circle {
    height: 12px;
    width: 12px;
    background: linear-gradient(rgba(60, 200, 235, 1), rgba(17, 113, 216, 1));
    border-radius: 50px;
}

@media (max-width: 450px) {
    .thank-you-modal .modal-content {
        min-width: 100%;
    }
}