@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;400;500;700&display=swap");

@font-face {
  font-family: "Tajawal";
  src: local("Tajawal Black"), url("../fontsAR/Tajawal-Medium.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: local("Tajawal Black"), url("../fontsAR/Tajawal-Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: local("Sohne"), url("../fontsAR/TestSöhne-Buch.otf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "SohneBold";
  src: local("SohneBold"), url("../fontsAR/TestSöhne-Kräftig.otf");
  font-weight: 900;
  font-style: normal;
}
.sideMenuAction {
  position: absolute;
  top: 15%;
  bottom: 0px;
  width: 7px;

  background: linear-gradient(0deg, #1171d8, #3cc8eb);
  height: 75%;
}
.sidebar-wrapper {
  display: flex;
  flex-direction: column;
}

/* .e-icons {
  font-family: "e-icons" !important ;
} */
.tox .tox-statusbar {
  display: none !important;
}

.tox-tinymce {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0px !important;
}

.tox-sidebar-wrap {
  padding: 10px !important;
}

.user_controls a {
  min-width: 80px;
  min-height: 32px;
  border: 1px solid rgb(176, 175, 175);
  border-radius: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px 8px 0px 8px;
  justify-content: center;
}

.user_controls a:nth-of-type(2) {
  padding: 0px 0px 0px 0px;
}

.upperForm_wrraper .form-control:focus,
.chat_wrapper .form-control:focus {
  box-shadow: none;
  border: 2px solid #4472f16c;
}
.category_card:hover {
  box-shadow: none;
  outline: 2px solid #4472f16c;
  /* border-bottom: 6px solid #4472f16c; */
  transition: border 0.2s ease-out;
}
.text_card:hover {
  box-shadow: none;
  outline: 2px solid #4472f16c;
  /* border: 2px solid #4472f16c;
  border-bottom: 6px solid #4472f16c; */
  /* transition: border 0.1s linear; */
}
.user_controls a span {
  font-size: 13px;
  font-weight: 700;
}

.user_controls a img {
  height: 15px;
  width: 15px;
}

.animationText {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.big_loading_modal {
  background-color: rgb(255, 255, 255);
  border: unset !important;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_gif {
  height: 150px;
}

.bigLoadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arabicFont {
  font-family: "Tajawal" !important;
}

.englishFont {
  font-family: "Poppins" !important;
}

.arabic_direction {
  direction: rtl;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  padding-top: 100px;
}
.left_hand_side::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  padding-top: 100px;
}
.sidebar-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  padding-top: 100px;
}
.chat_content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  padding-top: 100px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, #d851ef, #1877f2);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.row {
  margin: 0px;
}

.nav {
  display: flex !important;
  flex-direction: column;
  /* gap: 10px; */
  /* align-items: center; */
  justify-content: center;
}

.upgrade_sideMenu {
  /* position: absolute; */
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 8px;
  align-self: center;
  margin-top: 16px;
}

.upgrade_sideMenu label {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  opacity: 0.6;
}
.gpt_Menu_BTN {
  color: #fff;
  margin: 9px 10px;
  opacity: 0.86;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(47deg, #1171d8, #3cc8eb);
}
.arabyGPTForm {
  width: 100%;
  /* display: flex; */
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 0px 8px #80808038;
  border-radius: 15px;
}

.arabyGPTForm:hover .word_count {
  display: block;
}

.word_count {
  display: none;
}

.arabyGPTForm .form-control {
  border: unset !important;
}
.arabyGPTForm button {
  border: unset;
  background-color: transparent;
}
.arabyGPTForm button:disabled {
  filter: grayscale(100);
}
.arabyGPTForm button img {
  height: 20px;
  width: 20px;
}
.gpt_Menu_BTN img {
  width: 28px;
  height: 28px;
  margin: 0px;
  text-align: center;
  vertical-align: middle;
  float: left;
}
.gpt_Menu_BTN label {
  font-size: 11px;
  padding: 2px 4px;
  background-color: #a9daff;
  color: #013ea6 !important;
  border-radius: 3px;
  cursor: pointer;
}
.gpt_Menu_BTN:hover p {
  color: #fff !important;
}
.gpt_Menu_BTN:focus p {
  color: #fff !important;
}
.gpt_Menu_BTN p {
  margin-bottom: 0px;
}
.main-panel .navbar {
  position: relative;
}

.navbar .left_hand_side {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.upgrade_sideMenu a {
  width: 100%;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.page_wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.user_card {
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  gap: 10px;
  /* padding: 10px 10px 30px 10px; */
}

.user_card label {
  font-size: 15px;
  color: #fff;
  margin-bottom: 0px;
  /* margin-top: 0px; */
}

.userName {
  /* text-align: center; */
  cursor: pointer;
  font-size: 16px !important;
  margin-top: 0px;
}

.user_card img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #1171d8;
}

.main_container {
  background: none;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.user_card a {
  font-weight: unset;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-bottom: 1px dashed #3cc8eb;
}

.steps_left {
  height: 16px;
  width: 16px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(to bottom, #1171d8, #3cc8eb);
}

.wordCount_wrapper {
  width: 100%;
  /* gap: 10px; */
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: flex-end;
  justify-content: center;
  /* margin-top: 20px; */
  padding-bottom: 30px;
}

.svg_title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.left_hand_side {
  display: flex;
  padding: 0px !important;
}

.left_hand_side h3 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  /* margin: 0px; */
}

.navbar_button,
.navbar_button2,
.navbar_button3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background: linear-gradient(to right, #d851ef, #1877f2);
  border-radius: 10px;
  color: #fff;
  min-height: 36px;
  min-width: 110px;
  text-decoration: none;
  font-size: 14px;
}

.navbar_button2:hover {
  color: #ffffff;
  border: 1px solid #fff;
  background: transparent;
  border-top-color: #d851ef;
  border-left-color: #d851ef;
  border-bottom-color: #1877f2;
  border-right-color: #1877f2;
  box-shadow: 1px 0px 8px #d751ef86;
}
.navbar_button3:hover {
  color: #23273a;
  border: 1px solid #fff;
  background: transparent;
  border-top-color: #d851ef;
  border-left-color: #d851ef;
  border-bottom-color: #1877f2;
  border-right-color: #1877f2;
  box-shadow: 1px 0px 8px #d751ef86;
}
.navbar_button:hover {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  border-top-color: #d851ef;
  border-left-color: #d851ef;
  border-bottom-color: #1877f2;
  border-right-color: #1877f2;
  box-shadow: 1px 0px 8px #d751ef86;
}

.navbar_button:focus {
  color: #fff;
}
.navbar_button3:focus {
  color: #23273a;
}
/* .arabic_direction .logo-img {
  flex-direction: row-reverse;
} */

.navbar_button2:focus {
  color: #ffffff;
}

#sideMenuMobileBtn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: unset !important;
}

.word_count_wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word_count_wrapper label {
  color: #fff;
  margin: 0px;
}

.text_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.bars_wrraper {
  width: 100%;
}
.Homage_araby {
  height: calc(100vh - 110px);
}

.text_wrapper div {
  border: 1px dashed gray;
  height: 2px;
  flex-grow: 1;
}

.inner_bars_wrapper {
  overflow: hidden;
  width: 100%;
  border: 1px solid #fff;
  height: 20px;
  border-radius: 30px;
  box-shadow: 1px 0px 8px 5px #d851ef57;
}

.progress_bar {
  height: 18px;
  width: 20%;
  background: linear-gradient(to right, #d851ef, #b175ff);
  border-radius: 30px;
}

.inner_bars_wrapper_write {
  overflow: hidden;
  width: 100%;
  border: 1px solid #fff;
  height: 20px;
  border-radius: 30px;
  box-shadow: 1px 0px 8px 5px #1876f26f;
}

.progress_bar_write {
  height: 18px;
  width: 20%;
  background: linear-gradient(to right, #1877f2, #1c64b1);
  border-radius: 30px;
}

.search_input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 10px;
  /* border: 1px solid #e3e3e3; */
  border-radius: 12px;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.166);
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
}

.arabic_direction .form_buttions {
  background: linear-gradient(47deg, #1171d8, #3cc8eb);
  border-radius: 7px 0px 0px 7px;
}

.search_input_wrapper img {
  height: 25px;
  width: 25px;
}

.search_input_wrapper input {
  border: unset !important;
  border-radius: 14px;
}

.image_gen_header {
  display: flex;
  flex: 7;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  width: 60%;
}

.logo-img {
  width: 100%;
  display: flex;
  /* align-items: center; */
  color: #01bbfa;
  font-size: 15px;
  gap: 5px;
}

.logo-img label {
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  font-family: "Poppins" !important;
}

.form_buttions {
  background: linear-gradient(47deg, #1171d8, #3cc8eb);
  border: none;
  font-size: 14px;
  padding: 9px;
  border-radius: 0px 7px 7px 2px;
  color: #fff;
  box-shadow: 1px 0px 8px rgb(128 128 128 / 17%);
}

.new_forms {
  display: flex;
  justify-content: space-evenly;
}

.image_gen_header h4 {
  font-weight: 600;
  margin-top: 0px;
  color: #163064;
}
.image_gen_header p {
  font-weight: 600;
  margin-top: 0px;
  color: #6a7c85;
  font-size: 1.2rem;
}

.all_images_wrapper {
  /* min-height: 400px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
}

.image_gen_img_wrapper img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.claimPOPUP .modal-dialog .modal-content {
  overflow: hidden;
  box-shadow: 1px 0px 8px #8080809e;
}

.image_gen_img_wrapper {
  position: relative;
}

.image_gen_img_wrapper:hover .image_generation_options {
  opacity: 1;
}

.image_generation_options {
  position: absolute;
  transition: all 0.5s linear;
  top: 0px;
  left: 15px;
  right: 15px;
  bottom: 0px;
  opacity: 0;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.768);
  border-radius: 20px;
  transition: opacity 0.2s linear;
}
.tooltip_styling {
  width: 200px !important;
  font-family: "Tajawal";
}
.tooltip_styling_ar {
  width: 200px !important;
  font-family: "Tajawal";
  text-align: right;
}
.image_generation_options p {
  color: #fff;
  font-weight: 500;
}

.options_btn_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.text_card_content {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
}
.text_card_content p {
  flex: 9;
}
.engagment_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  /* justify-content: center; */
  flex: 1;
}
.engagment_text {
  font-size: 12px;
  /* font-weight: 800; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin: 0px;
  flex-direction: row-reverse;
}
.CircularProgressbar .CircularProgressbar-text {
  font-size: 25px !important;
  font-weight: 600;
}
.engagment_number {
  padding: 10px;
  border: 2px solid gray;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
}
.options_btn_wrapper button {
  min-width: 130px;
  border: unset;
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  font-size: 13px;
  transition: all 0.3s linear;
}

.options_btn_wrapper button:hover {
  border: 1px solid rgba(17, 113, 216, 1);
  background: transparent;
}

.all_images_wrapper {
  padding: 10px;
  display: flex;
  row-gap: 40px;
}

.categories_header {
  max-height: 70px;
  margin: 0px;
  z-index: 100;
  padding: 10px;
  width: 100%;
  display: flex;
  row-gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  /* padding-left: 50px; */
  /* gap: 10px; */
  /* background-color: #fff; */
  box-shadow: 1px 0px 8px #80808036;
}

.filter_wrapper {
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0px 5px 5px 5px;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.filter_wrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filter_wrapper a {
  /* border: 1px solid #dddddd; */
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* padding: 5px; */
  /* min-width: 118px; */
  /* width: 140px; */
  font-size: 12px;
}

.filter_wrapper a svg {
  width: 21px;
  height: 17px;
}

.filter_wrapper a {
  color: #000;
  border: 0px solid #26293a;
  border-radius: 20px;
  transition: 0.5s;
}

a.arabicFont,
a.englishFont {
  /* border: 1px solid #fff; */
  /* padding: 0px 2px 0px; */
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 22px; */
  /* min-width: 28px; */
  /* border-radius: 3px; */
}

a.arabicFont span {
  margin-bottom: 2px;
}

.filter_wrapper a:hover {
  color: #26293a;
  border: 1px solid #26293a;
}

.filter_wrapper a.active {
  background-color: #26293a;
  color: #fff;
  border: unset;
}

.filter_outer_wrraoer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  box-shadow: 1px 0px 8px #80808036;
}

.category_card {
  background-color: #fff;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.203);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  gap: 5px;
  position: relative;
  width: 100%;
  height: 210px !important ;
}
.cards_mains_cards {
  height: 230px !important;
}
.cards_mobile {
  position: absolute;
  bottom: 10px;
}

.category_card .cat_img {
  height: 82%;
  width: 35%;
  object-fit: contain;
}
.cat_img .spinner-border {
  color: #1f2233;
}

.write_body {
  /* height: calc(100vh - 129.19px); */
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  /* overflow: auto; */
}

.page_title {
  font-size: 16px;
  font-weight: 400;
}

.category_card label {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer !important;
  text-align: center;
}

.index_wrapper {
  row-gap: 10px;
  overflow-y: scroll;
  position: absolute;
  left: 0px;
  top: 150px;
  bottom: 0px;
  padding-bottom: 10px;
  scroll-behavior: smooth;
  padding: 10px 0px 10px 0px;
  height: calc(100vh - 190px);
}

.category_with_name_wrapper {
  /* justify-content: flex-end; */
  justify-content: center;
  padding: 20px 0px 20px 0px;
  /* position: relative; */
}

.category_with_name_body {
  justify-content: center;
  align-items: flex-start;
  row-gap: 40px;
  padding: 10px;
  max-height: calc(100vh - 190px);
  /* overflow-y: scroll; */
}

.Toastify__toast {
  font-family: "Tajawal" !important;
}

.sub_case_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  min-height: 50px;
}

.sub_case_header label {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.sub_case_header img {
  height: 20px;
  width: 20px;
}

.left_hand_side .form-group,
.left_hand_side form {
  width: 100%;
}
.left_hand_side .form_inner {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.left_hand_side form {
  background-color: #fafdff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  /* justify-content: space-between; */
}

.upperForm_wrraper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
}

/* .left_hand_side {
    background-color: #FAFDFF;
    min-height: calc(100vh - 70px);
    padding: 20px;
} */
.right_hand_side_category {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* margin-top: 100px; */
  height: calc(100vh - 70px);
  overflow: auto;
  margin-right: 0 !important;
}

.btn.common-btn {
  padding: 15px 60px;
  transform: scale(0.9);
  border-radius: 30px;
  background: rgb(17, 113, 216);
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff;
  font-size: 1rem;
  border: unset;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.common-btn:hover {
  transform: scale(1);
  border-radius: 30px;
  background-color: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  opacity: 1;
  color: #fff;
  transition: all 0.5s ease;
}

.btn.common-btn:focus {
  color: #fff;
}

.btn.common-card-btn {
  padding: 15px 60px;
  transform: scale(0.9);
  border-radius: 30px;
  background: rgb(17, 113, 216);
  background: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  color: #fff;
  font-size: 1rem;
  border: unset;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.common-card-btn:hover {
  transform: scale(1);
  border-radius: 30px;
  background-color: linear-gradient(
    45deg,
    rgba(17, 113, 216, 1) 0%,
    rgba(60, 200, 235, 1) 100%
  );
  opacity: 1;
  color: #fff;
  transition: all 0.5s ease;
}

.btn.common-card-btn:focus {
  color: #fff;
}

.text_card {
  min-height: 100px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 0px 8px rgb(128 128 128 / 14%);
  min-width: 90%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#engagment_tool img {
  height: 16px;
  width: 16px;
  margin-bottom: 1px;
  object-fit: cover;
}
.text_card_code {
  min-height: 100px;
  /* border-radius: 15px; */
  /* background-color: #fff; */
  /* box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.422); */
  width: 100%;
  /* padding: 10px; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.text_card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text_card_footer p {
  margin-bottom: 0px;
}

.text_card_actions_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}

/* justify-content: space-evenly; */
/* row-gap: 10px; */
/* } */
.main-panel {
  background: rgb(255, 255, 255) !important;
  background-color: rgb(255, 255, 255) !important;
}

.subCategories_content {
  position: relative;
  min-height: calc(100vh - 110px);
}

.inner_content {
  position: relative;
  height: 100%;
  background-color: #fff;
}

.subCtegoryHeader {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.inner_writeBody {
  min-height: calc(100vh - 70px);
  /* padding-top: 70px; */
  background-color: white;
}

.left_hand_side_form {
  position: absolute;
  left: 0px;
  top: 70px;
  bottom: 0px;
  /* height: 100%; */
  /* border-right: 0.5px solid #7070709d; */
  padding-bottom: 5px !important;
}

.sub_case_page_wrapper {
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - 70px);
  align-items: start;
  overflow-y: unset;
}

.right_hand_side_category {
  /* max-height: calc(100vh - 70px); */
  scroll-behavior: smooth;
  /* overflow-y: scroll; */
  row-gap: 30px;
}

.words_action_btn {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.words_action_btn:hover {
  background-color: rgba(128, 128, 128, 0.064);
}

@media (max-width: 991px) {
  .araby-gpt-text {
    font-size: 1.8rem !important;
    margin-top: 0 !important;
  }
  .landing h1,
  .homePageWrapper h1 {
    height: 40px;
  }
  /* .arabyGPT_form_wrapper{
    margin-bottom: 50px;
  } */

  .plan-box {
    margin: 0 !important;
  }
  .profile-width,
  .hredit {
    width: 100% !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .word_count_wrapper {
    width: 100%;
  }
  .GPTHeader {
    display: flex !important;
  }
  .Araby_GPT {
    padding-top: 70px;
    /* min-height: 100vh; */
  }
  .upgrade {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .write_body_height {
    height: calc(100vh - 180px);
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .sub_case_page_wrapper {
    height: 100% !important;
    overflow: auto;
  }
  .form-control {
    font-size: 1.3rem !important;
  }
}
/* @media (max-width: 1200px) and (min-width:768px) { */
@media (max-width: 1200px) {
  .write_body_height {
    height: calc(100vh - 217px);
    overflow: auto;
  }
  .Araby_GPT {
    height: calc(100vh - 71px) !important;
  }
  .user_controls {
    position: static !important;
  }
  .user_controls {
    padding: 0px !important;
    padding-top: 5px !important;
  }
  .navbar .left_hand_side {
    position: static;
    /* border: 2px solid #D3D3D3; */
    outline: none;
  }

  .plan_card_wrapper__oejeU {
    max-width: 100% !important;
  }

  .filter_wrapper a {
    /* min-width: 144px; */
  }

  .subCategories_content {
    position: relative;
    min-height: calc(100vh - 125px);
  }

  .left_hand_side_form {
    position: static;
    /* border: 2px solid #d3d3d3; */
    /* overflow: scroll; */
    outline: none;
    border: none;
  }

  .left_hand_side form,
  .left_hand_side .form_inner {
    gap: 20px;
  }

  .right_hand_side_category {
    height: 100%;
    overflow: auto;
  }

  .category_with_name_wrapper {
    justify-content: center;
  }

  .index_wrapper {
    display: none;
    padding: 5px;
    top: 126px;
    height: calc(100vh - 145px);
  }

  .subCategories_content {
    min-height: calc(100vh - 244px);
  }

  .category_with_name_body {
    min-height: auto;
    height: auto;
  }
  .sub_case_page_wrapper {
    height: calc(100vh - 70px);
    overflow-y: unset;
  }
}

.image_gen_outer_wraaper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafdff;
}

.innser_header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.innser_header button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 17px;
  font-weight: 800;
  color: gray;
  opacity: 0.6;
  padding: 5px;
}

.btn_active {
  border-bottom: 2px solid rgba(128, 128, 128, 0.49) !important;
  color: #000 !important;
  opacity: 1 !important;
}

.new_image_search_wrraper {
  width: 80%;
  display: flex;
  align-items: center;
}
.newImgForm {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.feeling_lucky_btn {
  padding: 5px;
  border: none;
  box-shadow: 1px 0px 8px rgb(128 128 128 / 31%);
  background-color: transparent;
  border-radius: 12px;
  min-width: 150px;
  min-height: 42px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.741);
}

.image_inner_wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.user_controls {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 15px;
  padding: 8px 10px 8px 10px;
  background: linear-gradient(to right, #151723, #23273a);
}

.user_controls i.ri-logout-box-r-line {
  font-size: 22px;
}

.user_controls a {
  text-decoration: none;
  color: #fff;
}

.user_controls a:hover {
  color: rgba(255, 255, 255, 0.733);
}

.user_controls a:focus {
  color: rgba(255, 255, 255, 0.733);
}

.user_controls a:active {
  color: rgba(255, 255, 255, 0.733);
}

.background_circle {
  /* position: absolute; */
  top: 35px;
  right: 0px;
  left: auto;
  bottom: 0px;
  width: 70%;
}
@media (max-width: 770px) {
  .background_circle {
    display: none !important;
  }

  .image_inner_wrapper {
    /* padding: 0 0 20px 0 !important; */
  }

  .image_not_found {
    position: unset !important;
    width: 70% !important;
    border-radius: 20px !important;
    margin: 0 auto !important;
  }
  .cards_mains_cards {
    height: 242px !important;
  }

  .cards_mobile {
    bottom: 13px;
  }
}

.image_not_found {
  position: absolute;
  top: 13%;
  right: 40px;
  left: auto;
  bottom: 0px;
  width: 197.5px;
  border-radius: 30px;
}

.image_inner_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.image_inner_content_wrapper h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.image_inner_content_wrapper p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.image_not_found_replaceMent {
  width: 100%;
  min-height: 400px;
}

.h6-home,
.h4-home {
  text-align: center;
}

@media (max-width: 991px) {
  .Nav_Business_tools .categories_header {
    width: 100% !important;
  }
  .write_body_height {
    height: calc(100vh - 129.19px);
    overflow: auto;
  }
  .landing {
    gap: 5px !important;
  }
  .examples_wrapper {
    margin-top: 0px !important;
  }
  .example_element p {
    font-size: 13px !important;
  }
  .example_element {
    min-height: auto !important;
  }
  .Araby_GPT_chat_space .chat_content {
    padding: 0px !important;
  }
  .chat_item_GPT {
    padding: 10px !important;
  }
  /* .chat_item_GPT:last-child {
    padding-bottom: 60px !important;
  } */
  .textImgCard {
    width: 100% !important;
  }
}
/* @media (min-width: 270px) and (max-width: 290px) { */
.chat_item_GPT:last-child {
  padding-bottom: 40px !important;
}
/* } */
/* @media (max-width : 991px) {
  .padding-for-generated{
    padding-bottom: 60px;
  }
  
} */

/* .index_wrapper {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 200px;
  } */
/* .category_with_name_wrapper {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */

@media (max-width: 991px) {
  .word_count_wrapper {
    order: 2;
  }
  #tett {
    text-align: center !important;
  }

  .enhance_header {
    font-size: 10px;
  }

  .upgrade {
    gap: 10px !important;
    padding-top: 10px;
  }
}
@media (max-width: 768px) {
  .category_card {
    /* width: auto; */
    height: 182px;
  }
  .category_card_height {
    height: 182px !important;
  }
}
@media (max-width: 600px) {
  .category_card .cat_img {
    max-height: 60px;
    min-height: 60px;
  }
}

@media (max-width: 500px) {
  .circle {
    height: 80px !important;
    width: 80px !important;
  }
  .toggle_button_plans {
    width: 300px !important;
  }

  #image_style_container_overflow {
    overflow-x: auto !important;
  }

  .image_style_container {
    flex-wrap: nowrap !important;
    /* display: block; */
    margin: auto !important;
    justify-content: center !important;
  }
  .image_style_container2 {
    /* display: block; */
    margin: auto !important;
    justify-content: center !important;
  }

  .image_style_buttons {
    width: 100px !important;
    height: 100px !important;
  }
  .modal_image {
    width: 90%;
  }
  .upgrade_sideMenu label {
    font-size: 12px;
    opacity: 0.8;
  }
  .user_controls a img {
    width: 20px;
    height: 20px;
  }
  .upgrade_sideMenu {
    padding: 5px;
  }
  .category_card {
    height: auto;
  }
  .category_card_height {
    height: auto !important;
  }

  .category_card .cat_img {
    max-height: 60px;
    min-height: 60px;
  }
  .text_card_content {
    flex-direction: column;
  }
  .category_card .cat_imgmg {
    width: 60%;
    height: auto;
    max-height: 73px;
  }

  .category_card label {
    font-size: 12px;
  }
  .sub_case_page_wrapper {
    height: 100%;
    overflow-y: unset;
  }

  /* .Araby_GPT {
    height: calc(100vh - 147px) !important;
  } */
}
@media (max-width: 576px) {
}
@media (min-width: 340px) {
  .lessNeeded {
    width: 50%;
  }
}

/* WORD COUNT COMPONENT CSS */

/* @media (max-width:991px) {
  .word-count-container {
    display: none !important;
  }
} */

.word-count-container {
  display: flex;
  min-width: 300px;
  max-width: 800px;
  gap: 20px;
  flex-wrap: wrap-reverse;
}

.cssmarquee {
  /* min-height: 44px; */
  overflow: hidden;
  /* padding: 5px; */
  width: 100%;
}
.cssmarquee_arab {
  /* height: 28px; */
  overflow: hidden;
  /* padding: 5px; */
  width: 100%;
}
.cssmarquee,
.cssmarquee_arab {
  white-space: nowrap;
  margin-bottom: 2px;
  text-align: center;
}

@keyframes cssmarquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes cssmarquee_arab {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(90%);
  }
}

.swal2-popup {
  font-family: "Tajawal" !important;
}
@media (max-width: 1111px) {
  .cssmarquee,
  .cssmarquee_arab {
    /* min-height: 33px; */
    /* width: 130px; */
  }
}
.container_code {
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  border-radius: 16px;
  padding: 10px;
  position: relative;
  /* box-shadow: 0 20px 30px -10px #26394d; */
}

.editor {
  /* padding-top: 70px; */
  direction: ltr;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#left-handler_code:hover {
  height: 44px;
  width: 12px;
  transition: all 0.15s;
}

#right-handler_code:hover {
  height: 44px;
  width: 12px;
  transition: all 0.15s;
}

#left-handler_code {
  background-color: white;
  transition: all 0.15s;
  height: 32px;
  width: 8px;
  position: absolute;
  left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}

#right-handler_code {
  background-color: white;
  transition: all 0.15s;
  height: 32px;
  width: 8px;
  position: absolute;
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
}

._code textarea:focus {
  outline: none;
}
.wrapper_code {
  margin-top: 40px;
  margin-bottom: 140px;
  border-radius: 16px;
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  padding: 56px;
  display: flex;
  position: relative;
  align-items: center;
  min-height: 450px;
}
.form_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.compiler_wrapper {
  width: 100%;
  /* flex: 1; */
  min-height: 312px;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.241);
}
.chat_wrapper {
  /* flex: 1; */
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
  /* min-height: 50%; */
  width: 100%;
}
.loadingModal .modal-content {
  background-color: transparent;
  border: none;
}
.chat_content {
  padding: 40px 0px 0px 0px;
  max-height: 70vh !important;
  scroll-behavior: smooth;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: 80vh;
  padding: 10px;
}
/* .Araby_GPT_chat_space .chat_content {
  max-height: 81vh;
} */
.codeFormSubmit {
  border: unset;
  background: linear-gradient(to right, #d851ef, #1877f2);
  color: #fff;
  border-radius: 10px;
  min-height: 36px;
  font-size: 14px;
}
.chat_item {
  display: flex;
  padding: 5px;
  flex-direction: column;
  width: 100%;
}
.chat_item_GPT {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.output_number {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  gap: 5px;
  flex-wrap: wrap;
}
.output_number button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  min-width: 44px;
  min-height: 41px;
  background-color: #fff;
  border: unset;
  border-radius: 6px;
  box-shadow: 1px 0px 8px #80808030;
}
.output_number button:hover {
  outline: 2px solid #4472f16c;
}
.selectedNumber {
  outline: 2px solid #4472f16c;
  color: #4472f1da;
}
.codeFormSubmit:disabled,
.common-btn:disabled,
.navbar_button2:disabled {
  filter: grayscale(100);
  cursor: not-allowed;
}
.chat_item:nth-child(even) {
  background-color: #ebf7ff;
}
/* .chat_item_GPT:nth-child(even) {
    background-color: #EBF7FF;
  } */

.chat_item label {
  color: rgba(128, 128, 128, 0.787);
  font-size: 12px;
  font-weight: 800;
  margin: 0px;
}
.chat_item_GPT img {
  height: 55px;
  width: 55px;
  border: 3px solid #fff;
  border-radius: 8px;
  box-shadow: 1px 0px 8px #80808024;
}
.chat_item_GPT label {
  color: rgba(128, 128, 128, 0.787);
  font-size: 12px;
  font-weight: 800;
  margin: 0px;
  width: 100%;
}
.plans_card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 0px 8px rgb(128 128 128 / 20%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border: 0.5px solid #d7d7d7;
  /* transform: scaleY(0.9); */
}
.upper_info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.popular {
  background-color: transparent !important;
  padding: 3px !important;
  border-radius: 3px !important;
}

.popular label {
  font-size: 11px !important;
  color: #d852f0;
}
.Pricing_head h1 {
  text-align: center;
  font-weight: 600;
  color: black;
  margin: 0;
}
.Pricing_head h4 {
  text-align: center;
  color: black;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}
.plan_name {
  padding: 8px 0;
  /* background-color: #80808057; */
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 65px;
  width: fit-content;
  border-radius: 5px;
}
.plan_name h6 {
  margin: 0;
  font-weight: 800;
  font-size: 1.8rem;
  color: black;
  text-transform: capitalize;
}
.plan_name label {
  font-size: 14px;
  font-weight: 600;
}
.plan_name label,
.upper_info p {
  margin: 0px;
}
.upper_info p {
  font-size: 14px;
}
.priceWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceWrapper h1 {
  margin: 10px 0 0 0px;
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}
.priceWrapper label {
  margin: 0px;
  font-size: 13px;
  font-weight: 500;
}
.priceWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.plan_details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  list-style: none;
}
.plan_details li {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  gap: 6px;
  font-size: 14px;
  margin: 4px 0;
}
.plan_details img {
  margin-bottom: 2px;
}
.subs_button {
  min-height: 36px;
  border: unset;
  color: #fff;
  border-radius: 8px;
  background-image: linear-gradient(225deg, #06f 0%, #00b3ff 100%);
  font-weight: 700;
  padding: 10px 0;
}

.sub_pro_button {
  min-height: 36px;
  border: unset;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  font-weight: 700;
  padding: 10px 0;
  background-image: linear-gradient(225deg, #06f 0%, #00b3ff 100%);
}

.sub_pro_button:hover {
  /* background: #fff !important;
  border: 1px solid #000;
  color: #000;
  transition: all 0.2s ease-out; */
}

.plan-type {
  background-color: #fafdff;
  min-height: 100vh;
}
.subs_button:hover {
  /* background-color: #fff !important; */
  /* border: 1px solid #000; */
  /* color: #000; */
  transition: all 0.2s ease-out;
}
.subs_button:disabled {
  background-color: #fff !important;
  border: 1px solid #000;
  color: #000;
  transition: all 0.2s ease-out;
}
.pop_wrapper {
  display: flex;
  align-items: center;
}
.form_buttions:disabled {
  background: gray;
  cursor: not-allowed;
}
.plans_categories_wrapper {
  display: flex;
  direction: ltr;
  align-items: flex-start;
  justify-content: space-between;
  /* min-height: 90px; */
  /* padding: 5px; */
  padding-top: 10px;
  /* padding-bottom: 0px; */
  background-color: #fff;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.269);
  overflow: hidden;
}
.plans_card_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #000;
  width: 30%;
  padding: 5px;

  /* min-height: 99px; */
}
.plans_card_sort .plan_btn_filter,
.plans_card_sort span {
  opacity: 0.4;
}
.plans_categories_wrapper a {
  text-decoration: none;
}
.plans_categories_wrapper a:focus,
.plans_categories_wrapper a:active,
.plans_categories_wrapper a:hover {
  color: #000 !important;
}
.plans_card_sort.active {
  transition: opacity 0.3s ease-in-out;
  border-bottom: 4px solid #006ceb;
  opacity: 1;
}
.plans_card_sort:hover {
  transition: opacity 0.3s linear;
  border-bottom: 4px solid #006ceb;
}
.plans_card_sort:hover .plan_btn_filter {
  color: #000 !important;
  opacity: 1;
}
.plans_card_sort.active .plan_btn_filter {
  color: #000 !important;
  opacity: 1;
}
.plan_btn_filter img {
  height: 25px;
  width: 25px;
}
.plans_categories_wrapper span {
  font-size: 13px;
}
.plan_btn_filter {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.plans_card_sort span {
  text-align: center;
}
@media (max-width: 375px) {
  .save-10-head {
    margin-left: -30px;
  }
  .Araby_GPT_chat_space {
    min-height: 53vh !important;
  }
  .example_element_header {
    padding: 5px !important;
  }
}

@media (max-width: 390px) {
  .filter-header-align {
    padding: 0 10px !important;
  }
  .examples_wrapper {
    gap: 0 !important;
  }
  .landing h1,
  .homePageWrapper h1 {
    margin: 0;
  }
  .inner_bars_wrapper_write {
    height: 10px !important;
  }
  .progress_bar_write {
    height: 8px !important;
  }
  .left_hand_side h3 {
    margin: 5px !important;
  }
  /* .Araby_GPT{
    padding-top: 50px !important ;
  } */
}
@media (max-width: 465px) {
  .enhance_wrapper_right {
    margin: auto;
  }
  .image_gen_header {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
}

@media (max-width: 465px) {
  .save10 {
    padding: 0 3px !important;
    margin-left: 2px !important;
    font-size: 10px !important;
  }

  .chat_item label {
    color: rgba(128, 128, 128, 0.787);
    font-size: 12px;
    font-weight: 800;
    margin: 0px;
  }
  .chat_item_GPT img {
    height: 55px;
    width: 55px;
    object-fit: cover;
    border: 3px solid #fff;
    border-radius: 8px;
    box-shadow: 1px 0px 8px #80808024;
  }
  .chat_item_GPT label {
    color: rgba(128, 128, 128, 0.787);
    font-size: 12px;
    font-weight: 800;
    margin: 0px;
    width: 100%;
  }
  .plans_card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 0px 8px rgb(128 128 128 / 20%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    /* transform: scaleY(0.9); */
  }
  .upper_info {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .plan_name {
    padding: 8px 0;
    /* background-color: #80808057; */
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    width: fit-content;
    border-radius: 5px;
  }
  .plan_name label {
    font-size: 14px;
    font-weight: 600;
  }
  .plan_name label,
  .upper_info p {
    margin: 0px;
  }
  .upper_info p {
    font-size: 14px;
  }
  .priceWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .priceWrapper h1 {
    margin: 0px;
    font-weight: 700;
    color: #000;
  } */
  .priceWrapper label {
    margin: 0px;
    font-size: 13px;
    font-weight: 500;
  }
  .priceWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plan_details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    list-style: none;
  }
  .plan_details li {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    gap: 6px;
    font-size: 14px;
  }
  .plan_details img {
    margin-bottom: 2px;
  }
  .subs_button {
    min-height: 36px;
    border: unset;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    font-size: 1.4rem;
  }
  .sub_pro_button {
    font-size: 1.4rem;
  }
  .plan-type {
    background-color: #fafdff;
  }
  .subs_button:hover {
    background-color: #fff !important;
    border: 1px solid #000;
    color: #000;
    transition: all 0.2s ease-out;
  }
  .subs_button:disabled {
    background-color: #fff !important;
    border: 1px solid #000;
    color: #000;
    transition: all 0.2s ease-out;
  }
  .pop_wrapper {
    display: flex;
    align-items: center;
  }
  .form_buttions:disabled {
    background: gray;
    cursor: not-allowed;
  }
  .landing .save10 {
    padding: 0 2px !important;
  }
  .plans_categories_wrapper {
    /* flex-direction: column; */
    align-items: center !important;
    justify-content: center;
    row-gap: 10px;
  }
  .plans_card_sort span {
    display: none;
  }
  .plan_btn_filter {
    font-size: 11px;
  }
  .plan_btn_filter img {
    height: 15px;
    width: 15px;
  }
  .plans_card_sort {
    width: 90% !important;
  }
}

.bookmark_height {
  height: calc(100vh - 174px);
  overflow: auto;
}
@media screen and (max-width: 1200px) {
  .bookmark_height {
    height: calc(100vh - 211px);
  }
}
@media screen and (max-width: 992px) {
  .bookmark_height {
    height: calc(100vh - 146px);
  }
}

.bookmarks_pageHeader a {
  color: #26293a;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 50px;
}

a.myActive {
  background-color: #26293a;
  color: #fff !important;
}

/* .bookmarks_pageHeader a:focus,
.bookmarks_pageHeader a:active {
  color: #030303;
} */
.bookmarks_pageHeader a:hover {
  color: #030303;
}
.custom_table_header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 7px;
  border: 1px solid gray;
  border-radius: 30px;
  gap: 10px;
}
.custom_table_row {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gray;
  gap: 10px;
  min-height: 100px;
}
.actionsCel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookmarks_page {
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
.custom_container {
  width: 90%;
  max-width: 1400px;
}
.custom_table_cel {
  flex: 1;
  display: flex;
  align-items: center;
}
.custom_table_cel label {
  margin: 0px;
}
.textCel {
  flex: 2;
  justify-content: center;
}

.Araby_GPT {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  /* padding-top: 70px; */
  gap: 0px;
  justify-content: space-between;
  /* height: calc(100vh - 110px); */
  /* overflow-y: hidden;   */
}
.GPT_Generated_Image {
  height: 200px !important;
  width: 200px !important;
}
.spinner-grow {
  animation: 1.3s linear infinite spinner-grow;
  color: rgb(114, 180, 247) !important;
}

.height {
  height: calc(100vh - 134.19px);
}

@media (max-width: 482px) {
  #image_gen_outer_wraaper {
    height: calc(100vh - 74.19px) !important;
  }
}
@media (max-width: 991px) {
  .plan_name h6 {
    font-size: 2.5rem;
  }

  .image_gen_outer_wraaper {
    height: calc(100vh - 87px) !important;
  }
}

@media (max-width: 1200px) {
  .wordCount_wrapper {
    justify-content: space-between;
  }
  .image_gen_outer_wraaper {
    height: calc(100vh - 147px);
  }
  .Araby_GPT_chat_space {
    min-height: calc(100vh - 147px) !important;
  }
}

.Araby_GPT_chat_space {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 110px);
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.arabyGPT_form_wrapper {
  flex-grow: 1;
  width: 75%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 11px 20px 20px;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
}
.landing {
  padding: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* min-height: 75vh; */
  text-align: center;
}
.examples_wrapper {
  row-gap: 10px;
}
.examples_wrapper {
  align-items: flex-end;
  width: 90%;
  margin-top: 50px;
}
.example_element {
  padding: 10px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 0px 8px #8080803b;
  border-radius: 10px;
  border: 1px solid #a5d9ff;
}
.example_element:hover {
  box-shadow: none;
  outline: 1px solid #4472f16c;
  /* border-bottom: 6px solid #4472f16c; */
  transition: border 0.2s ease-out;
}
.example_element p {
  margin: 0px;
  font-size: 16px;
}
.save10 {
  /* background-color: #cbffd0;
  border-radius: 4px;
  color: #D852F0;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0px 15px;
  position: absolute;*/
  margin-left: 10px;
  color: #d852f0;
  font-size: 0.8rem;
  font-weight: 600;
  position: absolute;
}

.landing h1 {
  height: 80px;
  font-size: 55px;
  margin-bottom: 0px;
  background: -webkit-linear-gradient(
    104.62deg,
    #0085e6 29.98%,
    #52b6ff 43.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homePageWrapper h1 {
  height: 80px;
  font-size: 55px;
  margin-bottom: 0px;
  background: -webkit-linear-gradient(
    104.62deg,
    #0085e6 29.98%,
    #52b6ff 43.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.example_element_header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px;
}
.example_element_header img {
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 50%;
  background-color: #e7f5ff;
}
.GPTHeader {
  display: none;
}
.cat_img_null {
  height: 10px;
  width: 10px;
  visibility: hidden;
}
.captchaModal .modal-content {
  border: unset;
  box-shadow: unset;
  background-color: transparent;
}
.captchClass {
  border: unset !important;
}
.g-recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .Araby_GPT_chat_space {
    min-height: 60vh;
    max-height: 70vh !important;
  }
  .Araby_GPT_chat_space .chat_content {
    max-height: 76vh !important;
  }
}

.ql-editor.ql-blank:focus::before {
  content: "" !important;
}
@media (max-width: 991px) {
  .word_count_wrapper {
    width: 100%;
  }
  .GPTHeader {
    display: flex !important;
  }
  /* .Araby_GPT {
    padding-top: 20px;
  } */
  .landing {
    min-height: 70vh;
  }

  .arabyGPT_form_wrapper {
    padding-bottom: 13px !important;
    width: 100% !important;
    z-index: 2;
  }
  /* .arabyGPT_form_wrapper label {
    width: 100% !important;
  } */
  .arabyGPTForm {
    width: 100%;
  }
}
.tawk_arabi_custom .myClass {
  background-color: #fff !important;
  background: #fff !important;
}

.profile-width {
  width: 74.6%;
}

.save-10-head {
  direction: ltr;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 50px;
}

.araby-gpt-plan-text {
  font-weight: 700;
  margin-bottom: 0 !important;
  text-align: center;

  background: linear-gradient(
    to right,
    #69aaf4 10%,
    #005ac0 60%,
    #ace0f9 60%,
    #fff 20%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text !important;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
.business-plan-text {
  line-height: 21px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: linear-gradient(
    to right,
    #f4cd69 10%,
    #f4a302 60%,
    #ff9900 60%,
    #fadc97 20%
  );
  background-size: 200% auto;
  color: #000;
  background-clip: text !important;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.radius-border-color {
  background: linear-gradient(270deg, #151723, #23273a);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.border-radius-top {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #fafdff;
  flex-grow: 1;
}

.chat_item_GPT pre {
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.058);
  padding: 20px 10px;
  border-radius: 10px;
  background: #fff;
}
.chat_item_GPT pre div {
  width: 100%;
  background: transparent !important;
  /* color: #fff; */

  direction: ltr;
  text-align: left;
}
.chat_item_GPT ol {
  width: 100%;
}
.gpt_history_wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 711px);
  flex-grow: 1;
  overflow: auto;
}
.gpt_element,
.gpt_element_active {
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0.86;
  border-radius: 4px;
  padding: 5px 15px;
}
.gpt_element .chat_link {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
}
.gpt_element_active {
  opacity: 1 !important;
  background: rgba(24, 119, 242, 0.1019607843) !important;
}
.gpt_element_active .chat_link {
  color: #b6d6ff !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  gap: 5px;
}
.delete_icon {
  display: none;
  z-index: 1000;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.gpt_element_active .delete_icon {
  display: block;
}
.gpt_element .chat_link img,
.gpt_element_active .chat_link img {
  height: 18px;
  width: 18px;
}
.gpt_element .chat_link p,
.gpt_element_active .chat_link p {
  margin-bottom: 0px;
}
.gpt_element:hover {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
  color: #fff;
}
.gpt_element:hover .chat_link {
  color: #fff;
}
.gpt_history_wrapper .gpt_element:hover .delete_icon {
  display: block;
}
.gpt_history_wrapper .chat_link:focus {
  color: #fff;
}
.gpt_history_wrapper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  padding-top: 100px;
}
.image-width-for-main {
  width: 55%;
}
.right-arrow-img {
  display: none;
}

@media (max-width: 575px) {
  .image-width-for-main {
    width: auto !important;
  }
  .user_controls a:nth-of-type(2) {
    border: none;
    min-width: 40px !important;
  }
  .user_controls a {
    border: none;
    min-width: 0px !important;
  }
  .delete_icon {
    display: block;
  }
  .user_controls {
    gap: 10px !important;
  }
}

.ratioDirstBtn button {
  min-width: 80px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
}

.newImageGenForm {
  transition: min-height 1s ease-in;
  min-height: 30px;
}

.button-anon-pen {
  background-image: linear-gradient(115deg, #d851ef, #1877f2);
  text-align: center;
  margin: 0 auto;
  padding: 4px;
  position: relative;
  color: #fff;
  text-decoration: none;
  display: block;
  top: 45vh;
  font-weight: normal;
  border-radius: 6px;
  overflow: hidden;
  animation: play 1.6s ease-in infinite;
  -webkit-animation: play 1.6s ease-in infinite;
}

.button-anon-pen span {
  padding: 14px 0;
  font-size: 22px;
  display: block;
  width: 100%;
  height: 100%;
  margin: 3px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
}
@keyframes play {
  0% {
    background-position: 0px;
  }
  20% {
    background-position: -110px;
  }
  35% {
    background-position: -180px;
  }
  50% {
    background-position: -210px;
  }
  80% {
    background-position: -350px;
  }
  100% {
    background-position: -390px;
  }
}
@-webkit-keyframes play {
  0% {
    background-position: 0px;
  }
  20% {
    background-position: -110px;
  }
  35% {
    background-position: -180px;
  }
  50% {
    background-position: -210px;
  }
  80% {
    background-position: -350px;
  }
  100% {
    background-position: -390px;
  }
}
/* .category_card {
    flex-direction: row !important;
    justify-content: space-around !important;
  } */
/* .category_card label {
    font-size: 20px !important;
  } */
/* .category_card .cat_img{
    width: unset !important;
  } */
.right-arrow-img {
  display: block !important;
}
/* } */
.table-striped {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.table::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  padding-top: 100px;
}
.table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, #d851ef, #1877f2);
  border-radius: 6px;
}

/* Handle on hover */
.table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.textImgCard tr {
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding: 5px;
}
.textImgCard th {
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding: 5px;
}
.textImgCard td {
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding: 5px;
}
.textImgCard thead {
  border-top: 1px solid gray;
}
.textImgCard thead {
  background-color: rgb(235, 247, 255);
  font-weight: bold;
}
.textImgCard thead tr th {
  /* background-color: rgb(235, 247, 255); */
  font-weight: bold;
  font-size: 18px;
}

.image_style_container {
  justify-content: flex-start;
  gap: 15px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
}
.image_style_container2 {
  justify-content: flex-start;
  gap: 15px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.image_style_buttons {
  height: 177px;
  width: 140px;
}
.gif_player img {
  height: 100%;
  width: 100%;
}
.videoBtnEffect span {
  background: linear-gradient(90deg, #151723, #23273a);
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  animation-name: btnPosition;
  animation-duration: 4s;
  animation-delay: 2s;
}
.videoBtnEffect span label {
  color: #fff;
  margin: 0px;
}
@keyframes btnPosition {
  from {
    bottom: -40px;
  }
  to {
    bottom: 5px;
  }
}
.newPlanCard {
  cursor: pointer;
}

.newPlanCard:hover {
  transform: scale(1.01);
  outline: 2px solid #005eff;
}
.arabyGPT_form_wrapper label.stop_and_re:hover {
  outline: 2px solid #4472f16c;
}
.arabyGPT_form_wrapper label.stop_and_re {
  background-color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s ease-in;
  transform: scale(1);
  outline: 1px solid rgba(90, 90, 90, 0.226);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
  /* border-radius: 1px; */
}
.arabyGPT_form_wrapper label.clear_btn:hover {
  background-color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s ease-in;
  transform: scale(1);
  border: 1px solid rgb(90, 90, 90);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
}
.newPlanCard:hover .plan_name {
  /* background-color: #005eff; */
}
.newPlanCard:hover .subs_button {
  background-color: #005eff;
}
.newPlanCard:hover .plan_name h6 {
  /* color: white !important; */
}

.toggle_button_plans {
  border: 0.5px solid #d7d7d7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 29px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 400px;
  margin: 10px auto;
}

.toggle_button_plans_1 {
  background: #005eff;
  color: white;
  padding: 8px;
  width: 50%;
  border: none;
  font-weight: 700;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 29px;
}
.toggle_button_plans_2 {
  background: transparent;
  border: none;
  width: 50%;
  font-weight: 700;
  padding: 8px;
  text-align: center;
}
.business_input_wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.circle {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 1px 0px 8px #80808038;
  border-radius: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  cursor: pointer;
}
.wave {
  background: linear-gradient(47deg, #1171d8, #3cc8eb);
  position: absolute;
  height: 200%;
  width: 200%;
  border-radius: 45%;
  left: -50%;
  transform: rotate(360deg);
  /* transition: all 3s ease; */
  animation: wave 25s linear infinite;
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}
/* 
.circle:hover .wave {
  top: 0%;
} */

.slides_tile_item_grabbing {
  cursor: grab;
}

.slides_tile_item_grabbing:active {
  cursor: grabbing !important;
}
.collapse_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.newCaregotyCard {
  height: 460px !important;
}
.comment-section {
  max-height: 300px;
  overflow-y: auto;
}

form {
  margin-bottom: 16px;
}

input {
  display: block;
  margin-bottom: 8px;
}

button {
  display: block;
}

.comment-list {
  margin-top: 16px;
}

.comment {
  background-color: #f2f2f2;
  padding: 8px;
  margin-bottom: 8px;
}

.comment p {
  margin: 0;
}

.comment strong {
  font-weight: bold;
}
.category_card_custom {
  height: 170px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: white;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.203);
  background-color: white;
}

.category_card_custom:hover {
  box-shadow: none;
  outline: 2px solid rgba(68, 114, 241, 0.4235294118);
  /* border-bottom: 6px solid #4472f16c; */
  transition: border 0.2s ease-out;
}
@media (max-width: 500px) {
  .small_text {
    font-size: 15px !important;
  }
}
@media (max-width: 1400px) {
  .category_card_custom {
    height: 154px;
    width: 180px;
  }
}
@media (max-width: 1300px) {
  .category_card_custom {
    height: 152px;
    width: 157px;
  }
}

.custom_dropdown_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.countryItemActive {
  background: rgb(1, 1, 1);
  outline: 1px solid rgb(1, 1, 1);
}
.countryItemActive label {
  color: #fff;
}

.countryItem {
  padding: 5px;
  border-radius: 10px;
}
.countryItemActive {
  background: rgb(1, 1, 1);
  outline: 1px solid rgb(1, 1, 1);
}
.countryItemActive label {
  color: #fff;
}
.countryItem:hover {
  /* background-color: rgba(128, 128, 128, 0.118); */
  background: rgb(1, 1, 1);
}
.countryItem:hover label {
  color: #fff;
}
.custom_dropdown_value {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: white;
}
.custom_dropdown_value span {
  opacity: 0.5;
}
.selectOption:hover {
  background-color: rgba(128, 128, 128, 0.063);
}
.bigWrapper {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
}
.custom_dropdown_list_wrapper {
  max-height: 270px;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.199);
  border: 1px solid #e3e3e3;
  overflow: auto;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  border-radius: 10px;
  z-index: 100;
  background-color: #fff;
}
.custom_dropdown_list_wrapper input {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  border: unset;
  border-bottom: 1px solid #e3e3e3;
}
.custom_dropdown_list_wrapper input:focus,
.custom_dropdown_list_wrapper input:active {
  outline: unset !important;
  border: unset;
  border-bottom: 1px solid #e3e3e3;
}
.custom_dropdown_list_wrapper ul {
  margin-bottom: 0px;
  padding: 0px 10px 0px 10px;
}
.custom_dropdown_list_wrapper ul li {
  list-style: none;
}
