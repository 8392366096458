.container_checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  margin-top: 10px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #1777f2;
  border-color: #1777f2;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.header-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.header-text {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 85%;
  margin: auto;
}

.header-selected {
  text-align: right;
  color: gray;
  font-size: 14px;
}
.remove_button {
  border-radius: 8px;
  cursor: pointer;
  border: none;
  padding: 5px 10px;
  background-color: #85c4ff;
  color: #fff;
}

.button_wrapper {
  border-radius: 12px;
  /* background: #b980f7; */
  padding: 10px 50px;
  border: none;
  color: #fff;
  font-weight: 900;
}
.proceed_button {
  background: #1777f2;
}

.cancel_button {
  background: #b6babe;
}
