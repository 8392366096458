.contact-us-section {
    background-color: #fafdff;
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-us-text-div {
    align-self: center;
    margin-top: 30px;
}

.contact-us-outer-form {
    align-self: center;
    width: 80%;
    border: 1px solid #fafdff;
    padding: 10px;
    height: 400px;
    border-radius: 25px;
}

.contact-us-inner-form {
    width: 100%;
    background-color: white;
    height: 100%;
    border-radius: 25px;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    gap: 20px;
}

.form-input-contact {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.textarea-contact {
    width: 100%;
}

.form-input-contact input {
    background-color: #f4f4f48a;
    border-radius: 38px;
    height: 50px;
    width: 100%;
    outline: none;
    padding-left: 25px;

}

.form-input-contact textarea {
    border: none;
    background-color: #f4f4f48a;
    border-radius: 20px;
    /* height: 50px; */
    width: 100%;
    padding: 15px;
    outline: none;


}

.name-email-input {
    width: 100%;
    display: flex;
    gap: 50px;
}

.contact-us-section {
    background: #fbf8ff;
    padding-top: 50px;
    height: 100%;
}

.cu-center {
    justify-content: center;
    text-align: center;
}

.cu-center h6 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.cuh1-contactus {
    width: 100%;
    color: #A5A9B5;
    font-size: 2.75rem;
    /* font-size: 3.5rem; */
    margin: 0px 0px 8px;
    padding: 0px 12px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.cuh1-contactus-caption {
    width: 100%;
    color: black;
    font-size: 2.75rem;
    /* font-size: 3.5rem; */
    margin: 0px 0px 8px;
    padding: 0px 12px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.cu-divborder {
    border-radius: 25px;
    border: 1px solid #d3d3d3;
    padding: 15px;
    margin: 2%;
    /* direction: rtl; */
}

.cu-divcontents {
    background: white;
    box-shadow: 0 0 10px #f3f3f3;
    border-radius: 10px;
}

input.cu-input {
    background: #f7f8f8;
    padding: 15px;
    width: 100%;
    border-radius: 25px;
    /* margin: 10px; */
    border: none;
}

.cu-textarea {
    background: #f7f8f8;
    padding: 15px;
    width: 100%;
    border-radius: 25px;
    /* margin: 10px; */
    margin: 0px;
    border: none;
    min-height: 200px;

}

.cu-textarea textarea {
    min-height: 200px;
    width: 100%;
    border: 0;
    background: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    overflow: auto;
    outline: none;
}

button.cu-btn {
    width: 100% !important;
    width: 150px !important;
    background: #7A14FF !important;
}

.cu-row {
    padding: 30px;
}

.cu-row-btn {
    direction: ltr;
}

@media only screen and (max-width: 600px) {
    .contact-us-section {
        margin-bottom: 0;
    }
}